<!--
 * @Author: 伽蓝
 * @Date: 2022-11-07 23:21:54
 * @LastEditTime: 2022-11-21 22:46:42
 * @LastEditors: 伽蓝
 * @FilePath: /pc-h5/src/components/work/mod/MarketingMix.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
  <div class="line-item" :class="{'compact-right': priceConfig.compactAlign === 'right', 'compact-left': priceConfig.compactAlign === 'left'}">
    <IconWrap v-if="iconInfo.beforeName.length > 0" position="before-name" :list="iconInfo.beforeName"></IconWrap>
    <div class="name" :style="[foodNameWidth]">
      <div class="po-r">
        <span v-if="info.aliasName" :style="nameFontStyle" v-html="transformHtml(info.aliasName)"></span>
        <span v-else :style="nameFontStyle">{{ info.foodName }}</span>
        <span>
          <span v-if="data.textNameNote1 === 'after-name' && info.note1" :style="nameNote1Style" v-html="transformHtml(info.note1)"></span>
          <span v-if="data.textNameNote2 === 'after-name' && info.note2" :style="nameNote2Style" v-html="transformHtml(info.note2)"></span>
        </span>
        <div class="under-name-note">
          <span v-if="data.textNameNote1 === 'under-name' && info.note1" :style="nameNote1Style" v-html="transformHtml(info.note1)"></span>
          <span v-if="data.textNameNote2 === 'under-name' && info.note2" :style="nameNote2Style" v-html="transformHtml(info.note2)"></span>
        </div>
      </div>
      <IconWrap v-if="iconInfo.afterName.length > 0" position="after-name" :list="iconInfo.afterName"></IconWrap>
    </div>
    <div class="flex align-center">
      <div class="price" :style="priceStyle.lineStyle">
        <IconWrap v-if="iconInfo.beforePrice.length > 0" :list="iconInfo.beforePrice" position="before-price"></IconWrap>
        <div class="price-item" :style="[i > 0 && priceItemStyle, { width: priceStyle.itemStyle.width }]" v-for="(item, i) in priceList" :key="i">
          <template v-if="item.price !== '' || iconInfo.priceHolder">
            <template v-if="priceConfig.compactAlign !== 'default' && i > 0 && (priceList[i - 1].price !== '' || iconInfo.priceHolder)">
              <IconWrap v-if="iconInfo.priceGap" position="between-price" :style="[priceDividerStyle]" :list="[iconInfo.priceGap]"></IconWrap>
              <span v-else-if="!data.textPriceCols && data.isYiHeTang" class="divider" :style="[priceDividerStyle, priceStyle.itemStyle]">/</span>
            </template>
            <span class="po-r" :style="getPriceItemStyle(item)">
              {{ item.price }}
              <IconWrap v-if="item.overIconList.length > 0" position="over-price" :list="item.overIconList"></IconWrap>
              <IconWrap v-if="item.afterIconList.length > 0" position="after-price" :list="item.afterIconList"></IconWrap>
            </span>
          </template>
          <span v-if="item.tagText" class="po-a tag-text" :style="tagTextStyle.lineStyle">
            <span class="po-r" :style="tagTextStyle.itemStyle">{{ item.tagText }}</span>
          </span>
        </div>
      </div>
      <div v-if="isShowDiscountPriceList" class="price" :style="discountStyle">
        <div class="price-item" :style="i > 0 && discountItemStyle" v-for="(item, i) in discountPriceList" :key="i">
          <span class="po-r" :style="discountPriceStyle.itemStyle">
            {{ item.price }}
            <IconWrap v-if="canShowDiscountPriceHolder && item.price === ''" position="over-price" :list="[iconInfo.priceHolder]"></IconWrap>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconWrap from './IconWrap.vue'
import { defaultTo, compareVersion, transformStyle, transformHtml } from 'tools/utils'
import { DEAULT_SMART_ICON_STYLE } from 'libs/BaseConfig'

export default {
  name: "BoxLine",
  props: {
    data: {
      type: Object,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    colIndex: {
      type: Number,
      default: 0
    },
    maxPriceCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isVersion3() {
      return compareVersion(this.data.version, '3.0.0') >= 0
    },
    priceConfig() {
      const { isYiHeTang, textPriceSort, textPriceCols, textPriceSpecs, textPriceCompact } = this.data
      const config = {
        colsCount: textPriceCols || 2,
        specs: textPriceSpecs || [],
        sort: textPriceSort || 'DESC',
        compactAlign: textPriceCompact || 'default',
      }
      if (!this.info.matchedSkuList && isYiHeTang) {
        config.sort = 'ASC'
        config.compactAlign = 'right'
      }
      return config
    },
    discountConfig() {
      return this.data.discountPriceConfig || {}
    },
    isShowDiscountPriceList() {
      const { showType, showPosition } = this.discountConfig
      return showType === 'discount-column' && showPosition === 'after-origin' && this.discountPriceList.length > 0
    },
    discountPriceList() {
      return this.info.showPriceList.map(item => {
        return {
          price: defaultTo(item.discountPrice),
        }
      })
    },
    allDiscountEmpty() {
      return this.discountPriceList.every(item => item.price === '')
    },
    canShowDiscountPriceHolder() {
      // 价格均未空时无需展示占位符
      return this.iconInfo.priceHolder && !this.allDiscountEmpty
    },
    matchedPriceList() {
      const { matchedSkuList } = this.info
      if (!matchedSkuList) return []
      const { specs, sort } = this.priceConfig
      // 不限展示规格时价格排序字段生效
      if (specs.length === 0) {
        return [...matchedSkuList]
          .sort((a, b) => sort === 'DESC' ? b.salePrice - a.salePrice : a.salePrice - b.salePrice)
      }
      // 限定展示规格时按限定规格顺序展示
      return specs.map(spec => {
        // 根据规格匹配到多个sku时, 展示价格最小的sku
        const skus = matchedSkuList
          .filter(item => item.specName.split(',').includes(spec))
          .sort((a, b) => a.salePrice - b.salePrice)
        return skus[0] || { salePrice: '', discountPrice: '' }
      })
    },
    priceList() {
      let priceList = []
      const { showType, originTagText } = this.discountConfig
      const compact = this.priceConfig.compactAlign
      const needChangePrice = showType === 'origin-tag' && !this.allDiscountEmpty
      if (this.info.showPriceList) {
        priceList = [...this.info.showPriceList]
        if (needChangePrice && compact !== 'default') {
          const [list, emptyList] = priceList.reduce((acc, cur) => {
            if (cur.discountPrice !== '') {
              acc[0].push(cur)
            } else {
              acc[1].push(cur)
            }
            return acc
          }, [[], []])
          if (compact === 'left') {
            priceList = [...list, ...emptyList]
          } else if (compact === 'right') {
            priceList = [...emptyList, ...list]
          }
        }
      } else if (this.data.isYiHeTang) {
        priceList = Object.values(this.info.units || {})
          .sort((a, b) => a - b)
          .reduce((acc, price) => {
            // 最多取两个价格; 价格重复的忽略
            if (acc.length > 1 || (acc[0] && defaultTo(price) === acc[0].price)) return acc
            acc.push({
              price: defaultTo(price),
            })
            return acc
          }, [])
      } else {
        priceList = ['priceMedium', 'priceBig'].map(key => ({ price: defaultTo(this.info[key]) }))
      }
      const lastHasPriceIndex = this.getLastHasPriceIndex(priceList, !needChangePrice ? 'price' : 'discountPrice')
      return priceList.map((v, index) => {
        const item = { ...v }
        if (needChangePrice) {
          const originPrice = item.price
          item.price = item.discountPrice
          item.discountPrice = originPrice
          if (originPrice !== '' && compact === 'default') {
            item.tagText = originTagText ? originTagText.replace(/{}/g, originPrice) : originPrice
          }
          if ((compact === 'left' && index === 0) || (compact === 'right' && index === priceList.length - 1)) {
            const showPrice = this.info.showPriceList.map(item => item.price).filter(item => item !== '').join('/')
            if (showPrice) {
              item.tagText = originTagText ? originTagText.replace(/{}/g, showPrice) : showPrice
            }
          }
        }
        item.afterIconList = []
        item.overIconList = []
        if (index === lastHasPriceIndex) { // 最后一个有金额或有占位符的价格后展示所有图标
          item.afterIconList = [...this.iconInfo.afterPrice]
        }
        if (item.price === '' && this.iconInfo.priceHolder) {
          item.overIconList = [this.iconInfo.priceHolder]
        }
        return item
      })
    },
    iconInfo() {
      const keyMap = {
        'before-name': 'beforeName',
        'after-name': 'afterName',
        'before-price': 'beforePrice',
        'after-price': 'afterPrice',
        'over-price': 'overPrice'
      }
      const initial = {
        beforeName: [],
        afterName: [],
        beforePrice: [],
        afterPrice: [],
        overPrice: [],
        sellOut: null,
        priceGap: null,
        priceHolder: null
      }
      return this.data.menuIconList.reduce((pre, item) => {
        // 无applyRange的说明是历史数据, 按需要展示处理
        if (item.url && (!item.applyRange || item.applyRange.text) && item.showPosition) {
          const cur = JSON.parse(JSON.stringify(item))
          cur.style = this.getStyle(cur)
          if (cur.isSystem) {
            pre[cur.id] = cur
          } else if (keyMap[cur.showPosition] && (this.isPreviewIcon || (this.info.attributes || []).includes(cur.name))) {
            pre[keyMap[cur.showPosition]].push(cur)
          }
        }
        return pre
      }, initial)
    },
    nameFontStyle() {
      const style = this.data.textNameStyle || this.data.style
      let { fontSize, letterSpacing, borderRadius, padding } = style
      return {
        ...style,
        fontSize: (this.data.textNameStyle ? fontSize : this.data.nameFontSize || 20) + 'px',
        letterSpacing: letterSpacing + 'px',
        borderRadius: borderRadius + 'px',
        padding: padding + 'px'
      }
    },
    nameNote1Style() {
      return transformStyle(this.data.textNameNote1Style)
    },
    nameNote2Style() {
      return transformStyle(this.data.textNameNote2Style)
    },
    priceStyle() {
      const { backgroundColor, borderRadius, padding, ...itemStyle } = transformStyle(this.data.textPriceStyle || this.data.style)
      const lineStyle = {
        backgroundColor,
        borderRadius,
        padding
      }
      if (this.priceConfig.compactAlign !== 'default') {
        lineStyle.width = itemStyle.width
        itemStyle.width = 'auto'
      }
      return {
        itemStyle,
        lineStyle,
      }
    },
    foodNameWidth() {
      const { textNameWidth } = this.data
      return {
        width: (textNameWidth || 162) + 'px'
      }
    },
    priceItemStyle() {
      const { textSpacingP } = this.data
      // 调整价格之间的间距
      return { marginLeft: textSpacingP + 'px' }
    },
    priceDividerStyle() {
      const { textSpacingP } = this.data
      return { left: -textSpacingP / 2 + 'px' }
    },
    discountStyle() {
      const marginLeft = this.discountConfig.spacingBefore
      return {
        ...this.discountPriceStyle.lineStyle,
        marginLeft: marginLeft + 'px',
      }
    },
    discountPriceStyle() {
      const { backgroundColor, borderRadius, padding, height, ...itemStyle } = transformStyle(this.data.textDiscountPriceStyle)
      return {
        itemStyle,
        lineStyle: {
          backgroundColor: this.allDiscountEmpty ? undefined : backgroundColor,
          borderRadius,
          padding,
          height: height + 'px',
        },
      }
    },
    discountItemStyle() {
      const { spacingItem } = this.discountConfig
      // 调整价格之间的间距
      return { marginLeft: spacingItem + 'px' }
    },
    tagTextStyle() {
      const { originTagBackground, spacingBefore, originTagTextOffset } = this.discountConfig
      const { itemStyle, lineStyle } = this.discountPriceStyle
      return {
        lineStyle: {
          ...itemStyle,
          ...lineStyle,
          lineHeight: lineStyle.height,
          backgroundImage: originTagBackground ? `url(${originTagBackground})` : '',
          marginTop: spacingBefore + 'px',
        },
        itemStyle: {
          left: originTagTextOffset + 'px',
        }
      }
    },
  },
  methods: {
    getStyle(item) {
      if (!item.applyRange) return {}
      const { text } = item.applyRange
      return text[this.colIndex] || DEAULT_SMART_ICON_STYLE
    },
    getPriceItemStyle(item) {
      const style = {
        ...this.priceStyle.itemStyle
      }
      if (item.price === '' && this.iconInfo.priceHolder) {
        style.minWidth = this.iconInfo.priceHolder.style.width + 'px'
      }
      return style
    },
    getLastHasPriceIndex(list, key = 'price') {
      let lastHasPriceIndex = -1
      if (this.iconInfo.priceHolder) {
        return list.length - 1
      }
      for (let i = list.length - 1; i >= 0; i--) {
        if (list[i][key] !== '') {
          lastHasPriceIndex = i
          break
        }
      }
      return lastHasPriceIndex
    },
    transformHtml
  },
  components: { IconWrap }
};
</script>

<style scoped lang="scss">
.line-item{
  display: flex;
  align-items: center;
  width: 368px;
  height: 24px;
  position: relative;
  box-sizing: border-box;
  &.compact-right{
    justify-content: space-between;
    .price{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .price-item{
        justify-content: flex-end;
        min-width: initial;
      }
    }
    .divider{
      padding: 0 5px;
      position: absolute;
      transform: translateX(-50%);
    }
  }
  &.compact-left{
    .price{
      text-align: left;
      .price-item{
        min-width: initial;
      }
    }
    .over-price{
      left: 0;
      right: initial;
      transform: translate(0, -50%);
    }
    .tag-text{
      left: 0;
    }
  }
  .tag-text {
    top: 100%;
    white-space: nowrap;
    background-size: 100% 100%;
    span {
      font-weight: inherit;
    }
  }

  .name{
    width: 162px;
    height: 24px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
  }
  .price{
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    .price-item{
      height: 24px;
      display: flex;
      min-width: 24px;
      align-items: center;
      justify-content: center;
      position: relative;
      :not(.tag-text){
        span{
          height: 24px;
          width: 40px;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }
}
.under-name-note{
  font-size: 0;
  position: absolute;
  top: 100%;
  left: 0;
}
</style>
