<template>
  <div class="td-content" :style="{...TdStyle}">
    <span
      class="content flex"
      :class="{isAnime:_isAnimeTD()}"
      v-html="cellData"
      :style="{
        textDecoration:TdStyle.textDecoration,
        alignItems:TdStyle.alignItems,
        justifyContent:TdStyle.justifyContent,
        padding:TdStyle.padding,
      }"
    ></span>
  </div>
</template>
<script>
export default {
  name: 'TableTdContent',
  props: {
    cellStyle: {
      type: Object,
      required: true
    },
    coordinate: {
      type: Array,
      required: true
    },
    tableData: {
      type: Object,
      required: true
    },
    tableDataIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    TdStyle () {
      const { cellStyle } = this
      let { padding, fontSize } = cellStyle
      padding && (padding = `0 ${padding}px`)
      !padding && (padding = '0 4px')
      fontSize && (fontSize = `${fontSize}px`)
      !fontSize && (fontSize = '30px')
      return Object.assign(cellStyle, { padding, fontSize })
    },
    cellData () {
      const { coordinate, tableDataIndex } = this
      const { tableCellDatas } = this.tableData
      const xy = coordinate
      return tableCellDatas[tableDataIndex][xy[0]][xy[1]]
    }
  },
  methods: {
    // 是否有动画
    _isAnimeTD (x, y) {
      const { tableHeadRowCount } = this.tableData
      if (tableHeadRowCount === 0) return true
      const { coordinate } = this
      if (coordinate[0] > tableHeadRowCount - 1) return true
    }
  }
}
</script>
<style scoped lang="stylus">
.td-content {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #000000;
  text-align: center;
  background: transparent;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1;
  font-weight: 400;
  font-style: normal;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    // 默认
    text-decoration: none;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
}
</style>
