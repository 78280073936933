<!--
 * @Author: 伽蓝
 * @Date: 2020-07-03 16:01:51
 * @LastEditTime: 2020-12-30 13:51:06
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web-view/src/components/mod/Weather.vue
 * @代码不规范,调试泪两行
-->
<template>
  <!-- <div :style="wrap"> -->
  <div :style="{...styleWH,...styleScale}">
    <div class="h-100 w-100 flex-between-center" :class="data.direction == 'row' ? '':'flex-column'">
      <div class="flex-around-start flex-column padding-tb po-r of-h w-100 h-100 box-border flex-basis"
        v-for="(item,index) in weather" :key="index">
        <div class="w-100 po-r pad-lr-sm border-box">
          <div style="width:170px">
            <img class="img" :src="src" />
          </div>
          <div class="po-a border-box text-32" style="right:10px;top:20px">{{item.temp1}}~{{item.temp2}}℃</div>
        </div>
        <div class="w-100 border-box flex-between-center text-32 pad-sm">
          <div>{{item.date}}</div>
          <div>{{item.weather}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
// 天气
import { WEATHER } from 'libs/BaseConfig'
const ROW_WIDTH = 900
const COL_WIDTH = 300
const ROW_HEIGHT = 300
const COL_HEIGHT = 900
export default {
  name: 'weather',
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    src () {
      return this.data.url || 'https://media.hesiling.com/h5/weather/00.png'
    },
    weather () {
      return WEATHER
    },
    wrap () {
      const { width, height } = this.data
      return {
        width: width + 'px',
        height: height + 'px'
      }
    },
    styleWH () {
      const { direction, background, style } = this.data
      const { color } = style

      return {
        width: direction === 'col' ? `${COL_WIDTH}px` : `${ROW_WIDTH}px`,
        height: direction === 'col' ? `${COL_HEIGHT}px` : `${ROW_HEIGHT}px`,
        transformOrigin: 'left top',
        background,
        color
      }
    },
    styleScale () {
      const { width, direction } = this.data
      const bl =
        direction === 'col'
          ? (width / COL_WIDTH).toFixed(4)
          : (width / ROW_WIDTH).toFixed(4)
      return { transform: `scale(${bl})` }
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.flex-basis {
  flex-basis: 33%
}
</style>
