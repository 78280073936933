// 入场动画数组
export const ENTER_ANIME_NAME_LIST = ['lightSpeedIn', 'bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInUp', 'fadeIn', 'fadeInDown', 'fadeInLeft', 'fadeInRight', 'fadeInUp']
// 整体运动
export function toPlayAnime ({ id, node, options, anime }) {
  const dom = node || document.getElementById(id)
  return dom.animate(ANI_LO[anime], options)
}
// 元素动画方法集合
export function toNormalAnime (idName, animeName, options) {
  const dom = document.getElementById(idName)
  const anime = dom.animate(ANI_LO[animeName], {
    ...options,
    direction: 'normal',
    fill: 'forwards'
  })
  return anime
}
// 动态热度
export function toDynamicHeat ({ idName, animeName, options }) {
  const dom = document.getElementById(idName)
  return dom.animate(ANI_LO[animeName], options)
}
// 走字动画
export function toMoveText ({ idName, animeName, options, startXY }) {
  const dom = document.getElementById(idName)
  const animeData = _setMoveText(animeName, startXY)
  const anime = dom.animate(animeData, {
    ...options,
    direction: 'normal',
    fill: 'forwards'
  })
  return anime
}
export function toMoveMap ({ idName, animeName, options, startXY }) {
  const dom = document.getElementById(idName)
  const animeData = _setMoveText(animeName, startXY)
  const anime = dom.animate(animeData, {
    ...options,
    direction: 'normal',
    fill: 'forwards'
  })
  return anime
}
// 播放特效动画
export function toPlaySpecial ({ id, node, options, anime }) {
  const dom = node || document.getElementById(id)
  return dom.animate(SPECIAL[anime], options)
}
// 特效动画
const SPECIAL = {
  // 雪人摇摆
  snowmanSwing: [
    { transform: 'rotate3d(0, 0, 1, 0deg)', transformOrigin: 'center bottom', offset: '0', easing: 'linear' },
    { transform: 'rotate3d(0, 0, 1, 8eg)', transformOrigin: 'center bottom', offset: '0.4', easing: 'linear' },
    { transform: 'rotate3d(0, 0, 1, -8deg)', transformOrigin: 'center bottom', offset: '0.7', easing: 'linear' },
    { transform: 'rotate3d(0, 0, 1, 0deg)', transformOrigin: 'center bottom', offset: '1', easing: 'linear' }
  ],
  // 热气动画
  smoke: [
    { transform: 'scale3d(0.4, 0, 1)', opacity: 1, transformOrigin: 'center bottom', offset: '0', easing: 'linear' },
    { transform: 'scale3d(0.82, 0.91, 1)', opacity: 1, transformOrigin: 'center bottom', offset: '0.7', easing: 'linear' },
    { transform: 'scale3d(1, 1.3, 1)', opacity: 0.1, transformOrigin: 'center bottom', offset: '1', easing: 'linear' }
  ]
}

const _setMoveText = (animeName, startXY) => {
  const copyData = JSON.parse(JSON.stringify(MOVE[animeName]))
  if (animeName === 'rightToLeft') {
    copyData[0].transform = `translateX(${startXY}px)`
    return copyData
  }
  if (animeName === 'topToBottom') {
    copyData[1].transform = `translateY(${startXY}px)`
    return copyData
  }
  if (animeName === 'leftToRight') {
    copyData[1].transform = `translateX(${startXY}px)`
    return copyData
  }
  if (animeName === 'bottomToTop') {
    copyData[0].transform = `translateY(${startXY}px)`
    return copyData
  }
}

const MOVE = {
  rightToLeft: [
    { transform: 'translateX(100%)', offset: '0' },
    { transform: 'translateX(-100%)', offset: '1' }
  ],
  leftToRight: [
    { transform: 'translateX(-100%)', offset: '0' },
    { transform: 'translateX(100%)', offset: '1' }
  ],
  topToBottom: [
    { transform: 'translateY(-100%)', offset: '0' },
    { transform: 'translateY(100%)', offset: '1' }
  ],
  bottomToTop: [
    { transform: 'translateY(100%)', offset: '0' },
    { transform: 'translateY(-100%)', offset: '1' }
  ],
  origin: [
    { transform: 'translateY(0)', offset: '0' },
    { transform: 'translateY(0)', offset: '1' }
  ]
}
const ANI_LO = {
  original: [
    { opacity: '1', offset: '0', easing: 'ease' },
    { opacity: '1', offset: '1', easing: 'ease' }
  ],
  bounce: [
    {
      transform: 'translate3d(0,0,0)',
      offset: '0',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0,0,0)',
      offset: '0.2',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0, -30px, 0)',
      offset: '0.4',
      easing: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)'
    },
    {
      transform: 'translate3d(0, -30px, 0)',
      offset: '0.43',
      easing: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)'
    },
    {
      transform: 'translate3d(0,0,0)',
      offset: '0.53',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0, -15px, 0)',
      offset: '0.7',
      easing: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)'
    },
    {
      transform: 'translate3d(0,0,0)',
      offset: '0.8',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    { transform: 'translate3d(0,-4px,0)', offset: '0.9', easing: 'ease' },
    {
      transform: 'translate3d(0,0,0)',
      offset: '1',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    }
  ],
  //
  flash: [
    { opacity: '1', offset: '0', easing: 'ease' },
    { opacity: '0', offset: '0.25', easing: 'ease' },
    {
      opacity: '1',
      offset: '0.5',
      easing: 'ease'
    },
    { opacity: '0', offset: '0.75', easing: 'ease' },
    { opacity: '1', offset: '1', easing: 'ease' }
  ],
  //
  headShake: [
    {
      transform: 'translateX(0)',
      offset: '0',
      easing: 'ease-in-out'
    },
    {
      transform: 'translateX(-6px) rotateY(-9deg)',
      offset: '0.065',
      easing: 'ease-in-out'
    },
    {
      transform: 'translateX(5px) rotateY(7deg)',
      offset: '0.185',
      easing: 'ease-in-out'
    },
    {
      transform: 'translateX(-3px) rotateY(-5deg)',
      offset: '0.315',
      easing: 'ease-in-out'
    },
    {
      transform: 'translateX(2px) rotateY(3deg)',
      offset: '0.435',
      easing: 'ease-in-out'
    },
    { transform: 'translateX(0)', offset: '0.5', easing: 'ease-in-out' },
    {
      transform: 'translateX(0)',
      offset: '1',
      easing: 'ease-in-out'
    }
  ],
  //
  jello: [
    { transform: 'none', offset: '0', easing: 'ease' },
    {
      transform: 'none',
      offset: '0.111',
      easing: 'ease'
    },
    {
      transform: 'skewX(-12.5deg) skewY(-12.5deg)',
      offset: '0.222',
      easing: 'ease'
    },
    {
      transform: 'skewX(6.25deg) skewY(6.25deg)',
      offset: '0.33299999999999996',
      easing: 'ease'
    },
    {
      transform: 'skewX(-3.125deg) skewY(-3.125deg)',
      offset: '0.444',
      easing: 'ease'
    },
    {
      transform: 'skewX(1.5625deg) skewY(1.5625deg)',
      offset: '0.555',
      easing: 'ease'
    },
    {
      transform: 'skewX(-0.78125deg) skewY(-0.78125deg)',
      offset: '0.6659999999999999',
      easing: 'ease'
    },
    {
      transform: 'skewX(0.390625deg) skewY(0.390625deg)',
      offset: '0.777',
      easing: 'ease'
    },
    {
      transform: 'skewX(-0.1953125deg) skewY(-0.1953125deg)',
      offset: '0.888',
      easing: 'ease'
    },
    {
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // pulse
  pulse: [
    { transform: 'scale3d(1, 1, 1)', offset: '0', easing: 'ease' },
    {
      transform: 'scale3d(1.05, 1.05, 1.05)',
      offset: '0.5',
      easing: 'ease'
    },
    { transform: 'scale3d(1, 1, 1)', offset: '1', easing: 'ease' }
  ],
  // rubberBand
  rubberBand: [
    { transform: 'scale3d(1, 1, 1)', offset: '0', easing: 'ease' },
    {
      transform: 'scale3d(1.25, 0.75, 1)',
      offset: '0.3',
      easing: 'ease'
    },
    { transform: 'scale3d(0.75, 1.25, 1)', offset: '0.4', easing: 'ease' },
    {
      transform: 'scale3d(1.15, 0.85, 1)',
      offset: '0.5',
      easing: 'ease'
    },
    { transform: 'scale3d(.95, 1.05, 1)', offset: '0.65', easing: 'ease' },
    {
      transform: 'scale3d(1.05, .95, 1)',
      offset: '0.75',
      easing: 'ease'
    },
    { transform: 'scale3d(1, 1, 1)', offset: '1', easing: 'ease' }
  ],
  // shake
  shake: [
    { transform: 'translate3d(0, 0, 0)', offset: '0', easing: 'ease' },
    {
      transform: 'translate3d(-10px, 0, 0)',
      offset: '0.1',
      easing: 'ease'
    },
    { transform: 'translate3d(10px, 0, 0)', offset: '0.2', easing: 'ease' },
    {
      transform: 'translate3d(-10px, 0, 0)',
      offset: '0.3',
      easing: 'ease'
    },
    { transform: 'translate3d(10px, 0, 0)', offset: '0.4', easing: 'ease' },
    {
      transform: 'translate3d(-10px, 0, 0)',
      offset: '0.5',
      easing: 'ease'
    },
    { transform: 'translate3d(10px, 0, 0)', offset: '0.6', easing: 'ease' },
    {
      transform: 'translate3d(-10px, 0, 0)',
      offset: '0.7',
      easing: 'ease'
    },
    { transform: 'translate3d(10px, 0, 0)', offset: '0.8', easing: 'ease' },
    {
      transform: 'translate3d(-10px, 0, 0)',
      offset: '0.9',
      easing: 'ease'
    },
    { transform: 'translate3d(0, 0, 0)', offset: '1', easing: 'ease' }
  ],
  // swing
  swing: [
    {
      transform: 'rotate3d(0, 0, 1, 0deg)',
      offset: '0',
      easing: 'ease'
    },
    { transform: 'rotate3d(0, 0, 1, 15deg)', offset: '0.2', easing: 'ease' },
    {
      transform: 'rotate3d(0, 0, 1, -10deg)',
      offset: '0.4',
      easing: 'ease'
    },
    { transform: 'rotate3d(0, 0, 1, 5deg)', offset: '0.6', easing: 'ease' },
    {
      transform: 'rotate3d(0, 0, 1, -5deg)',
      offset: '0.8',
      easing: 'ease'
    },
    { transform: 'rotate3d(0, 0, 1, 0deg)', offset: '1', easing: 'ease' }
  ],
  // centerSwing
  centerSwing: [
    {
      transform: 'rotate3d(0, 0, 1, 0deg)',
      transformOrigin: 'center center',
      offset: '0',
      easing: 'ease'
    },

    { transform: 'rotate3d(0, 0, 1, 15deg)', transformOrigin: 'center center', offset: '0.2', easing: 'ease' },
    {
      transform: 'rotate3d(0, 0, 1, -10deg)',
      transformOrigin: 'center center',
      offset: '0.4',
      easing: 'ease'
    },
    { transform: 'rotate3d(0, 0, 1, 5deg)', transformOrigin: 'center center', offset: '0.6', easing: 'ease' },
    {
      transform: 'rotate3d(0, 0, 1, -5deg)',
      offset: '0.8',
      transformOrigin: 'center center',
      easing: 'ease'
    },
    { transform: 'rotate3d(0, 0, 1, 0deg)', transformOrigin: 'center center', offset: '1', easing: 'ease' }
  ],
  // tada
  tada: [
    {
      transform: 'scale3d(1, 1, 1)',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)',
      offset: '0.1',
      easing: 'ease'
    },
    {
      transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)',
      offset: '0.2',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
      offset: '0.3',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)',
      offset: '0.4',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
      offset: '0.5',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)',
      offset: '0.6',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
      offset: '0.7',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)',
      offset: '0.8',
      easing: 'ease'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)',
      offset: '0.9',
      easing: 'ease'
    },
    { transform: 'scale3d(1, 1, 1)', offset: '1', easing: 'ease' }
  ],
  // wobble
  wobble: [
    {
      transform: 'none',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)',
      offset: '0.15',
      easing: 'ease'
    },
    {
      transform: 'translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)',
      offset: '0.3',
      easing: 'ease'
    },
    {
      transform: 'translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)',
      offset: '0.45',
      easing: 'ease'
    },
    {
      transform: 'translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)',
      offset: '0.6',
      easing: 'ease'
    },
    {
      transform: 'translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)',
      offset: '0.75',
      easing: 'ease'
    },
    { transform: 'none', offset: '1', easing: 'ease' }
  ],
  // bounceOut
  bounceOut: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      transform: 'scale3d(.9, .9, .9)',
      offset: '0.2',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale3d(1.1, 1.1, 1.1)',
      offset: '0.5',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale3d(1.1, 1.1, 1.1)',
      offset: '0.55',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'scale3d(.3, .3, .3)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // bounceOutDown
  bounceOutDown: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      transform: 'translate3d(0, 10px, 0)',
      offset: '0.2',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, -20px, 0)',
      offset: '0.4',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, -20px, 0)',
      offset: '0.45',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'translate3d(0, 2000px, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // bounceOutLeft
  bounceOutLeft: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '1',
      transform: 'translate3d(20px, 0, 0)',
      offset: '0.2',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'translate3d(-2000px, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // bounceOutRight
  bounceOutRight: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '1',
      transform: 'translate3d(-20px, 0, 0)',
      offset: '0.2',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'translate3d(2000px, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // bounceOutUp
  bounceOutUp: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      transform: 'translate3d(0, -10px, 0)',
      offset: '0.2',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, 20px, 0)',
      offset: '0.4',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, 20px, 0)',
      offset: '0.45',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'translate3d(0, -2000px, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeIn
  fadeIn: [
    { opacity: '0', offset: '0', easing: 'ease' },
    { opacity: '1', offset: '1', easing: 'ease' }
  ],
  // fadeInDown
  fadeInDown: [
    {
      opacity: '0',
      transform: 'translate3d(0, -100%, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInDownBig
  fadeInDownBig: [
    {
      opacity: '0',
      transform: 'translate3d(0, -2000px, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInLeft
  fadeInLeft: [
    {
      opacity: '0',
      transform: 'translate3d(-100%, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInLeftBig
  fadeInLeftBig: [
    {
      opacity: '0',
      transform: 'translate3d(-2000px, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInRight
  fadeInRight: [
    {
      opacity: '0',
      transform: 'translate3d(100%, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInRightBig
  fadeInRightBig: [
    {
      opacity: '0',
      transform: 'translate3d(2000px, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInUp
  fadeInUp: [
    {
      opacity: '0',
      transform: 'translate3d(0, 100%, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeInUpBig
  fadeInUpBig: [
    {
      opacity: '0',
      transform: 'translate3d(0, 2000px, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'ease'
    }
  ],
  // bounceIn
  bounceIn: [
    {
      opacity: '0',
      transform: 'scale3d(.3, .3, .3)',
      offset: '0',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'scale3d(1.1, 1.1, 1.1)',
      offset: '0.2',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'scale3d(.9, .9, .9)',
      offset: '0.4',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'scale3d(1.03, 1.03, 1.03)',
      offset: '0.6',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'scale3d(.97, .97, .97)',
      offset: '0.8',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'scale3d(1, 1, 1)',
      offset: '1',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    }
  ],
  // bounceInDown
  bounceInDown: [
    {
      opacity: '0',
      transform: 'translate3d(0, -3000px, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, 25px, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0, -10px, 0)',
      offset: '0.75',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0, 5px, 0)',
      offset: '0.9',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    }
  ],
  // bounceInLeft
  bounceInLeft: [
    {
      opacity: '0',
      transform: 'translate3d(-3000px, 0, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'translate3d(25px, 0, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(-10px, 0, 0)',
      offset: '0.75',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(5px, 0, 0)',
      offset: '0.9',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    }
  ],
  // bounceInRight
  bounceInRight: [
    {
      opacity: '0',
      transform: 'translate3d(3000px, 0, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'translate3d(-25px, 0, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(10px, 0, 0)',
      offset: '0.75',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(-5px, 0, 0)',
      offset: '0.9',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'none',
      offset: '1',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    }
  ],
  // bounceInUp
  bounceInUp: [
    {
      opacity: '0',
      transform: 'translate3d(0, 3000px, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, -20px, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0, 10px, 0)',
      offset: '0.75',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      transform: 'translate3d(0, -5px, 0)',
      offset: '0.9',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    },
    {
      opacity: '1',
      transform: 'translate3d(0, 0, 0)',
      offset: '1',
      easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    }
  ],
  // fadeOut
  fadeOut: [
    { opacity: '1', offset: '0', easing: 'ease' },
    { opacity: '0', offset: '1', easing: 'ease' }
  ],
  // fadeOut
  fade: [
    { opacity: '1', offset: '0', easing: 'ease' },
    { opacity: '0', offset: '1', easing: 'ease' }
  ],
  // fadeOutDown
  fadeOutDown: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(0, 100%, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutDownBig
  fadeOutDownBig: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(0, 2000px, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutLeft
  fadeOutLeft: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(-100%, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutLeftBig
  fadeOutLeftBig: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(-2000px, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutRight
  fadeOutRight: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(100%, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutRightBig
  fadeOutRightBig: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(2000px, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutUp
  fadeOutUp: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(0, -100%, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // fadeOutUpBig
  fadeOutUpBig: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(0, -2000px, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // flip
  flip: [
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, -360deg)',
      offset: '0',
      easing: 'ease-out'
    },
    {
      transform:
        'perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg)',
      offset: '0.4',
      easing: 'ease-out'
    },
    {
      transform:
        'perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg)',
      offset: '0.5',
      easing: 'ease-in'
    },
    {
      transform: 'perspective(400px) scale3d(.95, .95, .95)',
      offset: '0.8',
      easing: 'ease-in'
    },
    { transform: 'perspective(400px)', offset: '1', easing: 'ease-in' }
  ],
  // flipInX
  flipInX: [
    {
      transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease-in'
    },
    {
      transform: 'perspective(400px) rotate3d(1, 0, 0, -20deg)',
      offset: '0.4',
      easing: 'ease-in'
    },
    {
      transform: 'perspective(400px) rotate3d(1, 0, 0, 10deg)',
      opacity: '1',
      offset: '0.6',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px) rotate3d(1, 0, 0, -5deg)',
      offset: '0.8',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px)',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // flipInY
  flipInY: [
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, 90deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease-in'
    },
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, -20deg)',
      offset: '0.4',
      easing: 'ease-in'
    },
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, 10deg)',
      opacity: '1',
      offset: '0.6',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, -5deg)',
      offset: '0.8',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px)',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // flipOutX
  flipOutX: [
    {
      transform: 'perspective(400px)',
      opacity: '1',
      offset: '0',
      easing: 'ease',
      'backface-visibility': 'visible'
    },
    {
      transform: 'perspective(400px) rotate3d(1, 0, 0, -20deg)',
      opacity: '1',
      offset: '0.3',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease',
      'backface-visibility': 'visible'
    }
  ],
  // flipOutY
  flipOutY: [
    {
      transform: 'perspective(400px)',
      opacity: '1',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, -15deg)',
      opacity: '1',
      offset: '0.3',
      easing: 'ease'
    },
    {
      transform: 'perspective(400px) rotate3d(0, 1, 0, 90deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // lightSpeedIn
  lightSpeedIn: [
    {
      transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease-out'
    },
    {
      transform: 'skewX(20deg)',
      opacity: '1',
      offset: '0.6',
      easing: 'ease-out'
    },
    {
      transform: 'skewX(-5deg)',
      opacity: '1',
      offset: '0.8',
      easing: 'ease-out'
    },
    { transform: 'none', opacity: '1', offset: '1', easing: 'ease-out' }
  ],
  // lightSpeedOut
  lightSpeedOut: [
    {
      transform: 'none',
      opacity: '1',
      offset: '0',
      easing: 'ease-in'
    },
    {
      transform: 'translate3d(100%, 0, 0) skewX(30deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease-in'
    }
  ],
  // rotateOut
  rotateOut: [
    {
      transformOrigin: 'center',
      transform: 'none',
      opacity: '1',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'center',
      transform: 'rotate3d(0, 0, 1, 200deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateOutDownLeft
  rotateOutDownLeft: [
    {
      transformOrigin: 'left bottom',
      transform: 'none',
      opacity: '1',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'left bottom',
      transform: 'rotate3d(0, 0, 1, 45deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateOutDownRight
  rotateOutDownRight: [
    {
      transformOrigin: 'right bottom',
      transform: 'none',
      opacity: '1',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'right bottom',
      transform: 'rotate3d(0, 0, 1, -45deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateOutUpLeft
  rotateOutUpLeft: [
    {
      transformOrigin: 'left bottom',
      transform: 'none',
      opacity: '1',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'left bottom',
      transform: 'rotate3d(0, 0, 1, -45deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateOutUpRight
  rotateOutUpRight: [
    {
      transformOrigin: 'right bottom',
      transform: 'none',
      opacity: '1',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'right bottom',
      transform: 'rotate3d(0, 0, 1, 90deg)',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateIn
  rotateIn: [
    {
      transformOrigin: 'center',
      transform: 'rotate3d(0, 0, 1, -200deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'center',
      transform: 'none',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateInDownLeft
  rotateInDownLeft: [
    {
      transformOrigin: 'left bottom',
      transform: 'rotate3d(0, 0, 1, -45deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'left bottom',
      transform: 'none',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateInDownRight
  rotateInDownRight: [
    {
      transformOrigin: 'right bottom',
      transform: 'rotate3d(0, 0, 1, 45deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'right bottom',
      transform: 'none',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateInUpLeft
  rotateInUpLeft: [
    {
      transformOrigin: 'left bottom',
      transform: 'rotate3d(0, 0, 1, 45deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'left bottom',
      transform: 'none',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rotateInUpRight
  rotateInUpRight: [
    {
      transformOrigin: 'right bottom',
      transform: 'rotate3d(0, 0, 1, -90deg)',
      opacity: '0',
      offset: '0',
      easing: 'ease'
    },
    {
      transformOrigin: 'right bottom',
      transform: 'none',
      opacity: '1',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideInDown
  slideInDown: [
    {
      transform: 'translate3d(0, -100%, 0)',
      visibility: 'visible',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'translate3d(0, 0, 0)',
      visibility: 'visible',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideInLeft
  slideInLeft: [
    {
      transform: 'translate3d(-100%, 0, 0)',
      visibility: 'visible',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'translate3d(0, 0, 0)',
      visibility: 'visible',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideInRight
  slideInRight: [
    {
      transform: 'translate3d(100%, 0, 0)',
      visibility: 'visible',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'translate3d(0, 0, 0)',
      visibility: 'visible',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideInUp
  slideInUp: [
    {
      transform: 'translate3d(0, 100%, 0)',
      visibility: 'visible',
      offset: '0',
      easing: 'ease'
    },
    {
      transform: 'translate3d(0, 0, 0)',
      visibility: 'visible',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideOutDown
  slideOutDown: [
    {
      visibility: 'visible',
      transform: 'translate3d(0, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      visibility: 'hidden',
      transform: 'translate3d(0, 100%, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideOutLeft
  slideOutLeft: [
    {
      visibility: 'visible',
      transform: 'translate3d(0, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      visibility: 'hidden',
      transform: 'translate3d(-100%, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideOutRight
  slideOutRight: [
    {
      visibility: 'visible',
      transform: 'translate3d(0, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      visibility: 'hidden',
      transform: 'translate3d(100%, 0, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // slideOutUp
  slideOutUp: [
    {
      visibility: 'visible',
      transform: 'translate3d(0, 0, 0)',
      offset: '0',
      easing: 'ease'
    },
    {
      visibility: 'hidden',
      transform: 'translate3d(0, -100%, 0)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // hinge
  hinge: [
    {
      transform: 'none',
      transformOrigin: 'top left',
      opacity: '1',
      offset: '0',
      easing: 'ease-in-out'
    },
    {
      transform: 'rotate3d(0, 0, 1, 80deg)',
      transformOrigin: 'top left',
      offset: '0.2',
      easing: 'ease-in-out'
    },
    {
      transform: 'rotate3d(0, 0, 1, 60deg)',
      transformOrigin: 'top left',
      opacity: '1',
      offset: '0.4',
      easing: 'ease-in-out'
    },
    {
      transform: 'rotate3d(0, 0, 1, 80deg)',
      transformOrigin: 'top left',
      offset: '0.6',
      easing: 'ease-in-out'
    },
    {
      transform: 'rotate3d(0, 0, 1, 60deg)',
      transformOrigin: 'top left',
      opacity: '1',
      offset: '0.8',
      easing: 'ease-in-out'
    },
    {
      transform: 'translate3d(0, 700px, 0)',
      transformOrigin: 'top left',
      opacity: '0',
      offset: '1',
      easing: 'ease'
    }
  ],
  // jackInTheBox
  jackInTheBox: [
    {
      opacity: '0',
      transform: 'scale(0.1) rotate(30deg)',
      transformOrigin: 'center bottom',
      offset: '0',
      easing: 'ease'
    },
    { transform: 'rotate(-10deg)', offset: '0.5', easing: 'ease' },
    {
      transform: 'rotate(3deg)',
      offset: '0.7',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale(1)',
      transformOrigin: 'center center',
      offset: '1',
      easing: 'ease'
    }
  ],
  // rollIn
  rollIn: [
    {
      opacity: '0',
      transform: 'translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)',
      offset: '0',
      easing: 'ease'
    },
    { opacity: '1', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // rollOut
  rollOut: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg)',
      offset: '1',
      easing: 'ease'
    }
  ],
  // zoomIn
  zoomIn: [
    {
      opacity: '0',
      transform: 'scale3d(.3, .3, .3)',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      offset: '0.5',
      easing: 'ease'
    },
    { opacity: '1', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // zoomInDown
  zoomInDown: [
    {
      opacity: '0',
      transform: 'scale3d(.1, .1, .1) translate3d(0, -1000px, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(0, 60px, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.175, 0.885, 0.320, 1)'
    },
    { opacity: '1', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // zoomInLeft
  zoomInLeft: [
    {
      opacity: '0',
      transform: 'scale3d(.1, .1, .1) translate3d(-1000px, 0, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(10px, 0, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.175, 0.885, 0.320, 1)'
    },
    { opacity: '1', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // zoomInRight
  zoomInRight: [
    {
      opacity: '0',
      transform: 'scale3d(.1, .1, .1) translate3d(1000px, 0, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(-10px, 0, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.175, 0.885, 0.320, 1)'
    },
    { opacity: '1', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // zoomInUp
  zoomInUp: [
    {
      opacity: '0',
      transform: 'scale3d(.1, .1, .1) translate3d(0, 1000px, 0)',
      offset: '0',
      easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(0, -60px, 0)',
      offset: '0.6',
      easing: 'cubic-bezier(0.175, 0.885, 0.320, 1)'
    },
    { opacity: '1', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // zoomOut
  zoomOut: [
    { opacity: '1', transform: 'none', offset: '0', easing: 'ease' },
    {
      opacity: '0',
      transform: 'scale3d(.3, .3, .3)',
      offset: '0.5',
      easing: 'ease'
    },
    { opacity: '0', transform: 'none', offset: '1', easing: 'ease' }
  ],
  // zoomOutDown
  zoomOutDown: [
    {
      opacity: '1',
      transform: 'none',
      transformOrigin: 'center center',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(0, -60px, 0)',
      offset: '0.4',
      easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
    },
    {
      opacity: '0',
      transform: 'scale3d(.1, .1, .1) translate3d(0, 2000px, 0)',
      transformOrigin: 'center bottom',
      offset: '1',
      easing: 'cubic-bezier(0.175, 0.885, 0.320, 1)'
    }
  ],
  // zoomOutLeft
  zoomOutLeft: [
    {
      opacity: '1',
      transform: 'none',
      transformOrigin: 'center center',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(42px, 0, 0)',
      offset: '0.4',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'scale(.1) translate3d(-2000px, 0, 0)',
      transformOrigin: 'left center',
      offset: '1',
      easing: 'ease'
    }
  ],
  // zoomOutRight
  zoomOutRight: [
    {
      opacity: '1',
      transform: 'none',
      transformOrigin: 'center center',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(-42px, 0, 0)',
      offset: '0.4',
      easing: 'ease'
    },
    {
      opacity: '0',
      transform: 'scale(.1) translate3d(2000px, 0, 0)',
      transformOrigin: 'right center',
      offset: '1',
      easing: 'ease'
    }
  ],
  // zoomOutUp
  zoomOutUp: [
    {
      opacity: '1',
      transform: 'none',
      transformOrigin: 'center center',
      offset: '0',
      easing: 'ease'
    },
    {
      opacity: '1',
      transform: 'scale3d(.475, .475, .475) translate3d(0, 60px, 0)',
      offset: '0.4',
      easing: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
    },
    {
      opacity: '0',
      transform: 'scale3d(.1, .1, .1) translate3d(0, -2000px, 0)',
      transformOrigin: 'center bottom',
      offset: '1',
      easing: 'cubic-bezier(0.175, 0.885, 0.320, 1)'
    }
  ],
  // airplanePropeller
  airplanePropeller: [
    {
      transformOrigin: 'center',
      transform: 'rotate(-20000deg)',
      opacity: '1'
    },
    {
      transformOrigin: 'center',
      transform: 'none',
      opacity: '1'
    }
  ],
  // acidTrip
  acidTrip: [
    {
      filter: 'hue-rotate(0deg)',
      timingFunction: 'ease-in-out'
    },
    {
      filter: 'hue-rotate(360deg)',
      timingFunction: 'ease-in-out',
      offset: '0.5'
    },
    {
      filter: 'hue-rotate(-360deg)',
      timingFunction: 'ease-in-out'
    }
  ],
  // backdrop
  backdrop: [
    { backgroundColor: 'red', color: 'white' },
    { backgroundColor: 'orange', color: 'black', offset: '0.14' },
    { backgroundColor: 'yellow', color: 'black', offset: '0.28' },
    { backgroundColor: 'green', color: 'black', offset: '0.42' },
    { backgroundColor: 'blue', color: 'white', offset: '0.57' },
    { backgroundColor: 'indigo', color: 'white', offset: '0.71' },
    { backgroundColor: 'violet', color: 'white', offset: '0.85' },
    { backgroundColor: 'red', color: 'white' }
  ],
  // chameleon
  chameleon: [
    { color: 'red' },
    { color: 'orange', offset: '0.14' },
    { color: 'yellow', offset: '0.28' },
    { color: 'green', offset: '0.42' },
    { color: 'blue', offset: '0.57' },
    { color: 'indigo', offset: '0.71' },
    { color: 'violet', offset: '0.85' },
    { color: 'red' }
  ],
  glaucoma: [
    {
      opacity: '1',
      textShadow: '0 0 75px black',
      color: 'transparent',
      timingFunction: 'ease-out'
    },
    {
      opacity: '1',
      textShadow: '0 0 0 black',
      color: 'transparent',
      timingFunction: 'ease-out'
    }
  ],
  heartBeat: [
    { transform: 'scale(1.0)' },
    { transform: 'scale(1.3)', offset: '0.5' },
    { transform: 'scale(1.0)' }
  ],
  // lawnMower
  lawnMower: [
    { opacity: '1', transform: 'none', timingFunction: 'ease-in-out' },
    {
      opacity: '1',
      transform: 'translate3d(0, 0, 0) rotateY(12225deg)',
      timingFunction: 'ease-in-out'
    }
  ],
  // boomerang
  boomerang: [
    { zIndex: '1', transform: 'none' },
    { zIndex: '1', transform: 'rotate(-20deg)', offset: '0.1' },
    {
      zIndex: '1',
      transform: 'translate(220%, -55%) scale(0.5) rotate(-200deg)',
      offset: '0.35'
    },
    {
      zIndex: '1',
      transform: 'translate(-200%, 55%) scale(0.9) rotate(-860deg)',
      offset: '0.6'
    },
    { zIndex: '1', transform: 'none', offset: '0.80' },
    { zIndex: '1', transform: 'translate(0%, 0%) rotate(0deg)', offset: '0.9' },
    { zIndex: '1', transform: 'none' }
  ]
}
