var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.playAnimationSwitch,
          expression: "playAnimationSwitch",
        },
      ],
      ref: "smoke",
      style: _vm.style,
      attrs: { src: _vm.data.url },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }