var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("pages", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.loading,
        expression: "loading",
      },
    ],
    ref: "page",
    attrs: {
      "pages-data": _vm.pages,
      bl: _vm.bl,
      base: _vm.base,
      width: _vm.width,
      height: _vm.height,
      menuData: _vm.menuData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }