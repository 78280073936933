var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "smartDynamicMenu", style: _vm.style },
    [
      _vm.data.pictureMenu
        ? _c("PictureCollection", {
            class: _vm.modal ? "moveable-target" : "moveable-item",
            style: _vm.data.pictureStyle,
            attrs: {
              "data-name": "pictureStyle",
              onlyKey: _vm.data.onlyKey,
              data: _vm.data,
              info: _vm.info,
            },
          })
        : _vm._e(),
      _vm.data.textMenu
        ? _c("TextCollection", {
            class: _vm.modal ? "moveable-target" : "moveable-item",
            style: _vm.data.textStyle,
            attrs: {
              "data-name": "textStyle",
              onlyKey: _vm.data.onlyKey,
              data: _vm.data,
              info: _vm.info,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }