var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 h-100 po-r" }, [
    _c("video", {
      ref: "video",
      staticClass: "zindex-xl po-a",
      attrs: {
        width: _vm.data.width,
        height: _vm.data.height,
        preload: "auto",
        playsinline: true,
        "x5-video-player-type": "h5",
        "x5-video-player-fullscreen": false,
      },
      domProps: { muted: _vm.videoMuted },
      on: {
        ended: function ($event) {
          return _vm.onPlayerEnded($event)
        },
      },
    }),
    _c("div", { staticClass: "po-a top-0 left-0 w-100 h-100" }, [
      _c("img", { staticClass: "w-100", attrs: { src: _vm.url, alt: "" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }