var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "po-a of-s vw-100 vh-100 opacity-image" },
    [
      _vm._l(_vm.pagesData, function (page, i) {
        return _c("pages-layers", {
          key: page.onlyKey || page.key,
          staticClass: "po-a t-0 l-0 of-h origin-l-t",
          class: _vm.$globle.type === "mp" ? "disable-mouse-events" : "",
          style: { ..._vm.pageStyle, zIndex: _vm.pagesData.length - i },
          attrs: {
            id: `page_${i}`,
            "page-index": i,
            "current-index": _vm.currentIndex,
            page: page,
            "video-muted": _vm.videoMuted,
            width: _vm.width,
            menuData: _vm.menuData,
          },
        })
      }),
      _c("one-page-video-muted", {
        attrs: {
          "pages-data": _vm.pagesData,
          "open-video-sound": _vm.openVideoSound,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }