
<template>
  <div :style="style" class="w-100 h-100 of-h">
    <div
      :style="FontStyle"
      class="text-nowrap transparent"
      :id="`${data.type}_${data.onlyKey}`"
      :class="`${data.type}_${data.onlyKey} ${directionStyle}`"
      v-html="data.value"
    ></div>
  </div>
</template>

<script>
import { toMoveText } from 'libs/DocumentAnimates.js'
export default {
  name: 'moveText',
  data () {
    return { handelAnime: null, opacity: 0 }
  },
  props: {
    data: { type: Object }, playAnimationSwitch: { type: Boolean }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.play()
      if (!val) this.stop()
    }
  },
  computed: {
    style () {
      return { backgroundColor: this.data.style.backgroundColor, opacity: this.opacity }
    },

    directionStyle () {
      const { direction } = this.data
      if (direction === 'row') return 'inline-flex justify-start align-center h-100'
      return 'vertical-lr flex justify-center flex-direction w-100'
    },
    FontStyle () {
      const { style, fontFamily } = this.data
      const { fontSize, letterSpacing } = style
      const _style = {
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px'
      }
      return Object.assign(style, _style, { fontFamily })
    }
  },
  methods: {
    play () {
      const options = this.moveTextAnimeOptions()
      this.opacity = 1
      this.handelAnime = toMoveText(options)
    },
    stop () {
      this.handelAnime && this.handelAnime.pause()
      Object.assign(this.$data, this.$options.data())
    },
    moveTextAnimeOptions () {
      const { duration, type, onlyKey, direction } = this.data
      const { moveTextAnimeDir, width, height } = this.data
      const startXY = direction === 'col' ? height : width
      return {
        idName: `${type}_${onlyKey}`,
        animeName: moveTextAnimeDir,
        options: { duration: duration * 1000, iterations: 'Infinity' },
        startXY
      }
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.vertical-lr {
  writing-mode: vertical-lr;
}
</style>
