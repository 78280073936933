<!--
 * @Author: 伽蓝
 * @Date: 2020-12-15 09:42:48
 * @LastEditTime: 2020-12-17 17:30:14
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web-view/src/components/mod/Snowman copy.vue
 * @代码不规范,调试泪两行
-->
<template>
  <img :src="data.url" :style="style" />
</template>
<script>
export default {
  name: 'transparentVideo',
  props: {
    data: { type: Object }
  },
  computed: {
    style () {
      const { width, height } = this.data
      return {
        width: `${width}px`,
        height: `${height}px`
      }
    }
  }
}
</script>
