var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "w-100 h-100 mouse-events",
    style: { ..._vm.borderRadius, ..._vm.boxShadow, ..._vm.transform },
    attrs: { src: _vm.data.url },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }