var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "po-r", attrs: { id: `Table-${_vm.data.onlyKey}` } },
    [
      _c("div", { staticClass: "table-border" }),
      _c("div", { staticClass: "table-container" }, [
        _c("div", { staticClass: "po-r" }, [
          _c(
            "table",
            {
              staticClass: "box-border table",
              style: _vm.TableStyle,
              attrs: { cellspacing: "0px", cellpadding: "0px" },
            },
            [
              _c(
                "colgroup",
                _vm._l(_vm.data.tableColumns, function (col, c) {
                  return _c("col", {
                    key: c,
                    style: { width: _vm.data.tableCellWidth + "px" },
                  })
                }),
                0
              ),
              _c("thead", { staticClass: "thead" }),
              _c(
                "tbody",
                { staticClass: "tbody", attrs: { id: "tbody" } },
                _vm._l(_vm.data.tableRows, function (row, r) {
                  return _c(
                    "tr",
                    {
                      key: r,
                      staticClass: "tr",
                      style: { height: _vm.data.tableCellHeight + "px" },
                    },
                    _vm._l(_vm.data.tableColumns, function (col, c) {
                      return _c(
                        "td",
                        {
                          key: c,
                          staticClass: "td",
                          style: _vm.TdSTyle,
                          attrs: {
                            colspan: _vm._getMergeCellData(`${r}-${c}`, "col"),
                            rowspan: _vm._getMergeCellData(`${r}-${c}`, "row"),
                          },
                        },
                        [
                          _c("table-td-content", {
                            attrs: {
                              "cell-style": _vm.data.tableCellStyles[r][c],
                              coordinate: [r, c],
                              "table-data": _vm.data,
                              "table-data-index": _vm.datasIndex,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              ),
              _c("tfoot", { staticClass: "tfoot" }),
            ]
          ),
          _c("div", {
            staticClass: "po-a t-0 l-0 b-0 r-0 box-border z-index-l",
            style: _vm.tableBorder,
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }