var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "po-r", style: _vm.background },
    [
      _c("v-background-img", {
        attrs: { width: _vm.width, data: _vm.page.backgroundImage },
      }),
      _vm.isLivePlay
        ? _c("div", { staticClass: "po-a r-0 b-0 t-0 l-0 live-play-bg" })
        : _vm._e(),
      _vm._l(_vm.page.layers, function (layer, index) {
        return _c(
          "div",
          {
            key: layer.onlyKey,
            staticClass: "po-a origin-l-t",
            class: layer.onlyKey,
            style: _vm.layersStyle(layer, index),
            attrs: { id: layer.onlyKey },
          },
          [
            _c(_vm.getCurrentComponent(layer.type), {
              tag: "component",
              attrs: {
                data: layer,
                "page-index": _vm.pageIndex,
                "layer-index": index,
                "current-page": _vm.currentIndex,
                "video-list": _vm.page.videoList,
                "video-loop": _vm.page.videoLoop,
                "program-time": _vm.page.programTime - 0,
                "video-muted": _vm.videoMuted,
                "play-animation-switch": _vm.playAnimationSwitch,
                menuData: _vm.menuData,
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }