var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.videoMuted,
        expression: "videoMuted",
      },
    ],
    staticClass: "po-f vw-100 vh-100",
    class: _vm.$globle.type === "mp" ? "" : "z-index-l",
    on: { click: _vm.open },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }