/*
 * @Author: 伽蓝
 * @Date: 2022-11-07 22:14:14
 * @LastEditTime: 2022-11-14 20:28:26
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web/src/tools/CreateFont.js
 * @Description:
 * 代码不规范,调试泪两行
 */

class CreateFont {
  constructor () {
    console.log('在线字体类 创建')
  }

  createFontFamly ({ fontFamily, url }) {
    let style = document.getElementById(fontFamily)
    if (style) return
    const fontFace = this.getFontFace({ fontFamily, url })
    style = document.createElement('style')
    style.id = fontFamily
    style.type = 'text/css'
    style.innerText = fontFace
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(style)
  }

  getFontFace ({ fontFamily, url }) {
    const fontFace1 = `@font-face {font-family: ${fontFamily};src:url(`
    const fontFace2 = ") format('truetype')};"
    console.log('2123123 :>> ', `${fontFace1}'${url}'${fontFace2}`)
    return `${fontFace1}'${url}'${fontFace2}`
  }
}
let instance = null
const FontInstance = () => {
  if (instance) return instance
  instance = new CreateFont()
  return instance
}
export default FontInstance()
