/*
 * @Author: 伽蓝
 * @Date: 2020-11-11 15:13:51
 * @LastEditTime: 2022-11-14 19:55:41
 * @LastEditors: 伽蓝
 * @Description:
 * @FilePath: /h5-web/src/libs/BaseConfig.js
 * @代码不规范,调试两行泪
 */

const weatherData = {
  晴: '00',
  多云: '02',
  阴: '01',
  雨: '09',
  雪: '15',
  浮尘: '29',
  XX: 'undefined'
}
export const WEATHER = {
  f1: { date: 'XXXX/XX/XX', weather: '晴', temp1: 'XX', temp2: 'XX' },
  f2: { date: 'XXXX/XX/XX', weather: '晴', temp1: 'XX', temp2: 'XX' },
  f3: { date: 'XXXX/XX/XX', weather: '晴', temp1: 'XX', temp2: 'XX' }
}

function objToStrMap(obj) {
  const strMap = new Map()
  for (const k of Object.keys(obj)) {
    strMap.set(k, obj[k])
  }
  return strMap
}
export const WEATHER_CONFIG = objToStrMap(weatherData)
export const COMS_LIST = {
  image: 'VImagePic',
  text: 'VText',
  weather: 'VWeather',
  moveText: 'VMoveText',
  slideMap: 'VSlideMap',
  date: 'VDate',
  table: 'VTable',
  slide: 'VSlide',
  video: 'VVideo',
  callBoard: 'VCallBoard',
  dynamicData: 'VDynamicData',
  dynamicHeat: 'VDynamicHeat',
  snow: 'VSnow',
  snowman: 'VSnowman',
  transparentVideo: 'VTransparentVideo',
  smoke: 'VSmoke',
  virtualSales: 'VVirtualSales',
  limitedSales: 'VVirtualSales',
  livePlay: 'VLivePlay',
  cameraMonitor: 'VCameraMonitor',
  callOrderStatistics: 'VCallOrderStatistics',
  orderDetails: 'VOrderDetails',
  marketingMix: 'VMarketingMix',
  smartDynamicMenu: 'VSmartDynamicMenu',
}
// 叫号板 渠道与icon对应关系
// 1: '门店', 2: '外卖', 3: '小程序',
// 11: '小程序', 12: '美团', 13: '饿了么', 14: '门店',15:'公众号'
export const CALL_BOARD_ICON = {
  1: 'icon-mendian',
  2: 'icon-waimai',
  3: 'icon-xiaochengxu',
  11: 'icon-xiaochengxu',
  12: 'icon-meituan',
  13: 'icon-elemo',
  14: 'icon-mendian',
  15: 'icon-gongzhonghao'
}

export const DEAULT_SMART_ICON_STYLE = {
  offset: 0,
  height: 24,
  width: 24,
}
