var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "line-item",
      class: {
        "compact-right": _vm.priceConfig.compactAlign === "right",
        "compact-left": _vm.priceConfig.compactAlign === "left",
      },
    },
    [
      _vm.iconInfo.beforeName.length > 0
        ? _c("IconWrap", {
            attrs: { position: "before-name", list: _vm.iconInfo.beforeName },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "name", style: [_vm.foodNameWidth] },
        [
          _c("div", { staticClass: "po-r" }, [
            _vm.info.aliasName
              ? _c("span", {
                  style: _vm.nameFontStyle,
                  domProps: {
                    innerHTML: _vm._s(_vm.transformHtml(_vm.info.aliasName)),
                  },
                })
              : _c("span", { style: _vm.nameFontStyle }, [
                  _vm._v(_vm._s(_vm.info.foodName)),
                ]),
            _c("span", [
              _vm.data.textNameNote1 === "after-name" && _vm.info.note1
                ? _c("span", {
                    style: _vm.nameNote1Style,
                    domProps: {
                      innerHTML: _vm._s(_vm.transformHtml(_vm.info.note1)),
                    },
                  })
                : _vm._e(),
              _vm.data.textNameNote2 === "after-name" && _vm.info.note2
                ? _c("span", {
                    style: _vm.nameNote2Style,
                    domProps: {
                      innerHTML: _vm._s(_vm.transformHtml(_vm.info.note2)),
                    },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "under-name-note" }, [
              _vm.data.textNameNote1 === "under-name" && _vm.info.note1
                ? _c("span", {
                    style: _vm.nameNote1Style,
                    domProps: {
                      innerHTML: _vm._s(_vm.transformHtml(_vm.info.note1)),
                    },
                  })
                : _vm._e(),
              _vm.data.textNameNote2 === "under-name" && _vm.info.note2
                ? _c("span", {
                    style: _vm.nameNote2Style,
                    domProps: {
                      innerHTML: _vm._s(_vm.transformHtml(_vm.info.note2)),
                    },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm.iconInfo.afterName.length > 0
            ? _c("IconWrap", {
                attrs: { position: "after-name", list: _vm.iconInfo.afterName },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "flex align-center" }, [
        _c(
          "div",
          { staticClass: "price", style: _vm.priceStyle.lineStyle },
          [
            _vm.iconInfo.beforePrice.length > 0
              ? _c("IconWrap", {
                  attrs: {
                    list: _vm.iconInfo.beforePrice,
                    position: "before-price",
                  },
                })
              : _vm._e(),
            _vm._l(_vm.priceList, function (item, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "price-item",
                  style: [
                    i > 0 && _vm.priceItemStyle,
                    { width: _vm.priceStyle.itemStyle.width },
                  ],
                },
                [
                  item.price !== "" || _vm.iconInfo.priceHolder
                    ? [
                        _vm.priceConfig.compactAlign !== "default" &&
                        i > 0 &&
                        (_vm.priceList[i - 1].price !== "" ||
                          _vm.iconInfo.priceHolder)
                          ? [
                              _vm.iconInfo.priceGap
                                ? _c("IconWrap", {
                                    style: [_vm.priceDividerStyle],
                                    attrs: {
                                      position: "between-price",
                                      list: [_vm.iconInfo.priceGap],
                                    },
                                  })
                                : !_vm.data.textPriceCols && _vm.data.isYiHeTang
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "divider",
                                      style: [
                                        _vm.priceDividerStyle,
                                        _vm.priceStyle.itemStyle,
                                      ],
                                    },
                                    [_vm._v("/")]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "po-r",
                            style: _vm.getPriceItemStyle(item),
                          },
                          [
                            _vm._v(" " + _vm._s(item.price) + " "),
                            item.overIconList.length > 0
                              ? _c("IconWrap", {
                                  attrs: {
                                    position: "over-price",
                                    list: item.overIconList,
                                  },
                                })
                              : _vm._e(),
                            item.afterIconList.length > 0
                              ? _c("IconWrap", {
                                  attrs: {
                                    position: "after-price",
                                    list: item.afterIconList,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  item.tagText
                    ? _c(
                        "span",
                        {
                          staticClass: "po-a tag-text",
                          style: _vm.tagTextStyle.lineStyle,
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "po-r",
                              style: _vm.tagTextStyle.itemStyle,
                            },
                            [_vm._v(_vm._s(item.tagText))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              )
            }),
          ],
          2
        ),
        _vm.isShowDiscountPriceList
          ? _c(
              "div",
              { staticClass: "price", style: _vm.discountStyle },
              _vm._l(_vm.discountPriceList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "price-item",
                    style: i > 0 && _vm.discountItemStyle,
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "po-r",
                        style: _vm.discountPriceStyle.itemStyle,
                      },
                      [
                        _vm._v(" " + _vm._s(item.price) + " "),
                        _vm.canShowDiscountPriceHolder && item.price === ""
                          ? _c("IconWrap", {
                              attrs: {
                                position: "over-price",
                                list: [_vm.iconInfo.priceHolder],
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }