<template>
  <img
    :style="{...borderRadius,...boxShadow,...transform}"
    :src="data.url"
    class="w-100 h-100 mouse-events"
  />
</template>
<script>
import ModComsStyleMixin from 'mixin/ModComsStyleMixin'
export default {
  mixins: [ModComsStyleMixin],
  name: 'image-pic'
}
</script>
