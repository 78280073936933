
export default {
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    borderRadius () {
      const { borderRadius } = this.data
      return {
        borderRadius: borderRadius + '%'
      }
    },
    boxShadow () {
      const boxShadow = this.data.boxShadow
        .map((value, i) => {
          typeof value === 'number' && (value = `${value}px`)
          return value
        })
        .join(' ')
      return {
        boxShadow
      }
    },

    transform () {
      const { angle } = this.data
      return { transform: `rotate(${angle}deg)` }
    }

  }
}
