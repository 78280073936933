<template>
  <div class="icon-wrap" :class="[position, vertical ? 'vertical' : '']">
    <div class="icon-item" v-for="(item, i) in list" :key="i" :style="getStyle(item)">
      <img class="img-cover" :src="item.url" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      validator(value) {
        return ['before-name', 'after-name', 'before-price', 'after-price', 'over-price', 'between-price'].includes(value)
      }
    },
    vertical: Boolean,
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getStyle(item) {
      const { style } = item
      if (!style) return {}
      const isLeft = ['before-name', 'before-price'].includes(this.position)
      return {
        width: style.width + 'px',
        height: style.height + 'px',
        transform: `${(this.vertical && ['before-name', 'after-name'].includes(this.position)) ? 'translateY' : 'translateX'}(${isLeft ? -style.offset : style.offset}px)`,
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.icon-wrap
  display flex
  align-items center
  flex-shrink 0
  &:not(.after-name)
    position absolute
  &.after-price
    margin-left 2px
    left 100%
    top 50%
    transform translate(0, -50%)
  &.over-price
    left 50%
    top 50%
    transform translate(-50%, -50%)
  &.between-price
    padding 0 5px
    transform translateX(-50%)
  &:not(.vertical)
    &.before-name, &.before-price
      right 100%
      margin-right 2px
    &.after-name
      margin-left 2px
  &.vertical
    &.before-name
      margin-bottom 2px
      bottom 100%
      left 50%
      transform translate(-50%, 0%)
    &.after-name
      margin-top 2px
    &.before-price
      right 100%
      margin-right 2px
    &.before-name, &.after-name
      width 1em
      flex-direction column
      .icon-item
        + .icon-item
          margin-top 8px
          margin-left 0
.icon-item
  width 24px
  height 24px
  display flex
  justify-content center
  align-items center
  +.icon-item
    margin-left 8px
.img-cover
  width 100%
  height 100%
</style>
