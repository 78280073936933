var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.data.pictureMenuClass }, [
    _c(
      "div",
      { staticClass: "img-wrap po-r", style: _vm.pictureImgSpacingTop },
      [
        _c("img", {
          staticClass: "img-cover",
          style: _vm.pictureImageStyle,
          attrs: { src: _vm.info.image },
        }),
      ]
    ),
    _c(
      "div",
      { staticClass: "content-wrap", style: _vm.pictureImgSpacingLeft },
      [
        _c("div", { staticClass: "name", style: _vm.nameFontStyle }, [
          _c(
            "p",
            {
              staticClass: "flex align-center po-r",
              class: { "flex-wrap": _vm.isNameVerticalAlign },
              style: _vm.foodNameStyle,
            },
            [
              _vm.iconInfo.beforeName.length > 0
                ? _c("IconWrap", {
                    attrs: {
                      position: "before-name",
                      list: _vm.iconInfo.beforeName,
                      vertical: _vm.isNameVerticalAlign,
                    },
                  })
                : _vm._e(),
              _vm.info.aliasName
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.transformHtml(_vm.info.aliasName)),
                    },
                  })
                : _c("span", [_vm._v(_vm._s(_vm.info.foodName))]),
              _vm.iconInfo.afterName.length > 0
                ? _c("IconWrap", {
                    attrs: {
                      position: "after-name",
                      list: _vm.iconInfo.afterName,
                      vertical: _vm.isNameVerticalAlign,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { class: _vm.priceLayoutClass, style: _vm.priceStyle },
          _vm._l(_vm.priceList, function (item, i) {
            return _c(
              "div",
              { key: i, staticClass: "price-item", style: _vm.priceItemStyle },
              [
                item.beforeIconList.length > 0
                  ? _c("IconWrap", {
                      attrs: {
                        position: "before-price",
                        list: item.beforeIconList,
                        vertical: _vm.isNameVerticalAlign,
                      },
                    })
                  : _vm._e(),
                item.price !== ""
                  ? [
                      _c(
                        "span",
                        { staticClass: "po-r", style: _vm.priceFontStyle },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.data.showPriceSign,
                                  expression: "data.showPriceSign",
                                },
                              ],
                            },
                            [_vm._v("¥")]
                          ),
                          _c("span", [_vm._v(_vm._s(item.price))]),
                          _vm.iconInfo.overPrice.length > 0
                            ? _c("IconWrap", {
                                attrs: {
                                  position: "over-price",
                                  list: _vm.iconInfo.overPrice,
                                  vertical: _vm.isNameVerticalAlign,
                                },
                              })
                            : _vm._e(),
                          item.afterIconList.length > 0
                            ? _c("IconWrap", {
                                attrs: {
                                  position: "after-price",
                                  list: item.afterIconList,
                                  vertical: _vm.isNameVerticalAlign,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
      ]
    ),
    _c("div", {
      ref: "emElement",
      staticClass: "emWidth",
      style: [_vm.nameFontStyle],
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }