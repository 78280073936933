import { BigNumber } from 'bignumber.js'

const func = (funcName, val1, val2) => new BigNumber(val1)[funcName](new BigNumber(val2)).toNumber()

export const getTimesVal = (val1, val2) => func('times', val1, val2)

export const getDividedVal = (val1, val2) => func('dividedBy', val1, val2)

export const getMinus = (val1, val2) => func('minus', val1, val2)

export const getSum = (val1, val2) => func('plus', val1, val2)

/**
 * 格式化数据并四舍五入
 * @param digits 展示位数小数位数
 */
export const numFormatter = (digits = 0) => val => {
  if (Number.isNaN(+val)) return ''
  return BigNumber(+val).toFixed(digits)
}

export const numFormatter2 = numFormatter(2)

// 去除价格后多余的0
export const formatPrice = (price) => {
  if (price === 0) return '0'
  if (!price) return ''
  price = (+price).toString()
  const dotIndex = price.indexOf('.')
  if (dotIndex > -1) {
    price = price.substring(0, dotIndex + 3)
  }
  return price
}
