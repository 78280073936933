<!--
 * @Author: hsl-lmx
 * @Date: 2021-10-27 14:52:21
 * @LastEditTime: 2021-10-28 13:54:51
 * @LastEditors: Please set LastEditors
 * @Description: 叫号订单统计
 * @FilePath: \pc-h5\src\components\work\mod\CallOrderStatistics.vue
-->
<template>
    <div class='flex no-wrap' :style='{ ...style }'>
        <span v-if="statisticsType === '1'">{{unCookGoodsQuantity}}{{unit}} / {{unCookOrderQuantity}}单</span>
        <span v-if="statisticsType === '2'">{{unCookGoodsQuantity}}{{unit}} / {{goodsQuantity}}{{unit}}</span>
        <span v-if="statisticsType === '3'">{{unCookOrderQuantity}}单 / {{orderQuantity}}单</span>
        <span v-if="statisticsType === '4'">{{waitingTime}}分钟</span>
    </div>
</template>

<script>
export default {
  components: {},
  name: 'call-order-statistics',
  props: {
    data: { type: Object }
  },
  watch: {
  },
  data () {
    return {
      unCookGoodsQuantity: 0,
      unCookOrderQuantity: 0,
      goodsQuantity: 0,
      orderQuantity: 0,
      waitingTime: 0
    }
  },
  computed: {
    style () {
      const { width, height, style } = this.data
      const { fontSize, letterSpacing, textAlign } = style
      const { fontFace, fontFamily } = this.data
      const justifyContent =
        textAlign === 'left'
          ? 'flex-start'
          : textAlign === 'right' ? 'flex-end' : textAlign
      const style2 = {
        fontFace,
        fontFamily,
        width: width + 'px',
        height: height + 'px',
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px',
        justifyContent
      }
      return Object.assign({}, { ...style }, style2)
    },
    // 单位
    unit () {
      return this.data.configuration.unit
    },
    // 统计类型
    statisticsType () {
      return this.data.configuration.statisticsType
    }
  }
}
</script>

<style scoped lang='stylus' rel='stylesheet/stylus'>
.no-wrap {
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
}
</style>
