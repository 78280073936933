/*
 * @Author: 伽蓝
 * @Date: 2020-11-11 15:13:51
 * @LastEditTime: 2022-11-14 19:54:53
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web/src/mixin/ImportComsMixin.js
 * @代码不规范,调试泪两行
 */
import VBackgroundImg from 'coms/mod/BackgroundImg'
import VImagePic from 'coms/mod/Image'
import VText from 'coms/mod/Text'
import VSlide from 'coms/mod/Slide'
import VWeather from 'coms/mod/Weather'
import VMoveText from 'coms/mod/MoveText'
import VSlideMap from 'coms/mod/SlideMap'
import VDate from 'coms/mod/Date'
import VTable from 'coms/mod/Table'
import VVideo from 'coms/mod/Video'
import VDynamicData from 'coms/mod/DynamicData'
import VDynamicHeat from 'coms/mod/DynamicHeat'
import VSnow from 'coms/mod/Snow'
import VSnowman from 'coms/mod/Snowman'
import VTransparentVideo from 'coms/mod/TransparentVideo'
// import VVideoJS from 'coms/mod/VideoJS' // video js 的替代 暂时无用 勿删
import VCallBoard from 'coms/mod/CallBoard'
import VSmoke from 'coms/mod/Smoke'
import VVirtualSales from 'coms/mod/VirtualSales'
import VLivePlay from 'coms/mod/LivePlay'
import VCameraMonitor from 'coms/mod/CameraMonitor'
import VCallOrderStatistics from 'coms/mod/CallOrderStatistics.vue'
import VOrderDetails from 'coms/mod/OrderDetails.vue'
// 营销组件
import VMarketingMix from 'coms/mod/MarketingMix.vue'
// 智能动态菜单
import VSmartDynamicMenu from 'coms/mod/smartDynamicMenu/smartDynamicMenu.vue'

export default {
  components: {
    VSmartDynamicMenu,
    VMarketingMix,
    VOrderDetails,
    VCallOrderStatistics,
    VCameraMonitor,
    VLivePlay,
    VVirtualSales,
    VTransparentVideo,
    VSnowman,
    VSnow,
    VBackgroundImg,
    VImagePic,
    VText,
    VWeather,
    VMoveText,
    VSlideMap,
    VDate,
    VTable,
    VSlide,
    VVideo,
    // VVideoJS,
    VCallBoard,
    VDynamicData,
    VDynamicHeat,
    VSmoke
  }
}
