var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.url
    ? _c("img", {
        staticClass: "po-a mouse-events",
        style: _vm.style,
        attrs: { src: _vm.url },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }