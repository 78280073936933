var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "text-style display-block w-100 h-100 outline-none",
    style: { ..._vm.style, ..._vm.boxShadow, ..._vm.transform },
    domProps: { innerHTML: _vm._s(_vm.result) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }