<!--
 * @Author: 伽蓝
 * @Date: 2022-11-07 23:21:54
 * @LastEditTime: 2022-11-21 22:46:42
 * @LastEditors: 伽蓝
 * @FilePath: /pc-h5/src/components/work/mod/MarketingMix.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
  <div class="picture-collection" :style="style">
    <div class="box-row" :style="spacingR" v-for="(row, ri) in Number(data.pictureRow)" :key="ri + Math.random().toString(8)">
      <div class="box-cell" :style="spacingC" v-for="(col, ci) in data.pictureCol" :key="ci + Math.random().toString(8)">
        <PictureItem :data="data" :info="getInfo(ri, ci)" v-if="getInfo(ri, ci)" :rowIndex="ri"></PictureItem>
      </div>
    </div>
  </div>
</template>

<script>
import PictureItem from "./PictureItem.vue";
export default {
  components: { PictureItem },
  name: "PictureCollection",
  props: {
    data: {
      type: Object,
      default() {
        return {
          images: [],
          text: []
        }
      },
      required: true
    },
    info: {
      type: Object,
      required: true
    }
  },

  computed: {
    style() {
      let { pictureRow, pictureCol, pictureWidth, pictureHeight, pictureSpacingC, pictureSpacingR } = this.data;
      let { pictureMenuClass } = this.data
      if (pictureMenuClass == 'img-top') {
        pictureWidth = 180;
        pictureHeight = 344;
      }
      let height = pictureRow * pictureHeight + (pictureRow - 1) * pictureSpacingR;
      let width = pictureCol * pictureWidth + (pictureCol - 1) * pictureSpacingC;
      return {
        width: width + "px",
        height: height + "px",
      };
    },
    spacingC() {
      let { pictureSpacingC } = this.data;
      return {
        marginRight: pictureSpacingC + "px",
      };
    },
    spacingR() {
      let { pictureSpacingR } = this.data;
      return {
        marginBottom: pictureSpacingR + "px",
      };
    },
  },
  mounted() {
    // console.log('PictureCollection===========>', this.info);
  },
  methods: {
    getInfo(row, col) {
      try {
        let { pictureCol } = this.data;
        let index = row * pictureCol + col;
        return this.info.images[index]
      } catch (error) {
        return null
      }
    }
  }
};
</script>

<style scoped lang="scss">

.picture-collection{
  .box-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:last-child{
      margin-bottom: 0 !important;
    }
  }
  .box-cell{
    &:last-child{
      margin-right: 0 !important;
    }
  }
}
</style>
