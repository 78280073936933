<template>
  <div class="po-r mouse-events">
    <div class="po-a" :style="{ zIndex:firstZ }" :id="`${onlyKey}_first`">
      <img :src="firstUrl" :style="imageStyle" />
    </div>
    <div class="po-a" :style="{ zIndex:lastZ }" :id="`${onlyKey}_last`">
      <img :src="lastUrl" :style="imageStyle" />
    </div>
  </div>
</template>
<script>
import H5AnimeHandler from 'libs/H5AnimeHandler'
export default {
  name: 'slide',
  data () {
    return {
      handelAnime: [],
      firstIndex: 0,
      lastIndex: 1,
      firstZ: 1,
      lastZ: 0,
      currentPlayCount: 0,
      playIndex: 0
    }
  },
  props: {
    data: { type: Object },
    programTime: { type: Number },
    isAndroidSrc: { type: String, default: '' },
    playAnimationSwitch: { type: Boolean }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.play()
      if (!val) this.stop()
    }
  },

  computed: {
    onlyKey () {
      return this.data.onlyKey
    },
    imageStyle () {
      const { width, height } = this.data
      return { width: width + 'px', height: height + 'px' }
    },
    onlyOneImage () {
      return this.data.slideList.length === 1
    },
    imageList () {
      const { slideList } = this.data
      const { isAndroidSrc } = this
      return slideList.map(item => {
        isAndroidSrc !== '' && (item.url = isAndroidSrc + item.url.split('/').reverse()[0])
        return item
      })
    },
    firstUrl () {
      const { firstIndex, imageList } = this
      return imageList[firstIndex].url
    },
    lastUrl () {
      const { lastIndex, imageList, onlyOneImage } = this
      return onlyOneImage ? imageList[0].url : imageList[lastIndex].url
    },
    delay () {
      const { slideAnime } = this.data
      return slideAnime.duration * 1000 // 停留
    },
    duration () {
      const { slideAnime } = this.data
      return slideAnime.speed * 1000 // 过渡
    },
    anime () {
      const { slideAnime } = this.data
      return slideAnime.class
    },
    // playAnimeCount: 0 // 为负数时 代表不能整除
    playAnimeCount () {
      let { programTime, delay, duration } = this
      programTime = (programTime - 1) * 1000
      if (programTime % (delay + duration) === 0) {
        return programTime / (delay + duration)
      }
      return -1
    }
  },
  methods: {
    play () {
      const { playIndex } = this
      const config = this._getAnimeOptions(playIndex)
      const animes = H5AnimeHandler.playAnime(config)
      animes.onfinish = async () => {
        if (this._isStop()) return
        this.handelAnime.length = 0;
        [this.firstZ, this.lastZ] = [this.lastZ, this.firstZ]
        await this.nextPlayIndex(playIndex)
        await this.play()
      }
      this.handelAnime.push(animes)
    },
    stop () {
      this.$log.log('幻灯片 结束')
      this.handelAnime.map((item) => { item.cancel() })
      Object.assign(this.$data, this.$options.data())
    },
    _isStop () {
      let { currentPlayCount, playAnimeCount } = this
      if (playAnimeCount < 0) return false
      currentPlayCount += 1
      this.$log.log('播放了:', currentPlayCount, '总数 ', playAnimeCount)
      Object.assign(this, { currentPlayCount })
      if (currentPlayCount < playAnimeCount) return false
      return true
    },
    _getAnimeOptions (index) {
      let { duration, onlyKey, delay, anime } = this
      const { currentPlayCount, playAnimeCount } = this
      currentPlayCount + 1 === playAnimeCount && (anime = 'original')
      const id = index % 2 === 0 ? `${onlyKey}_first` : `${onlyKey}_last`

      let nowIndex = this.playIndex + 0
      if (this.playIndex >= this.imageList.length) {
        nowIndex = this.playIndex % this.imageList.length
      }
      const list = this.imageList[nowIndex]
      console.log('index', nowIndex)
      console.log('list', list, list.durationTime, delay)
      delay = (list.durationTime || (delay / 1000)) * 1000
      console.log('index', index, delay)

      return { id, anime, options: { duration, delay, fill: 'none' } }
    },
    // 更换图片url
    nextPlayIndex (playIndex) {
      const { imageList, onlyOneImage } = this
      let nextIndex = playIndex + 2 // 下一个图片索引
      if (nextIndex >= imageList.length) {
        nextIndex = nextIndex % imageList.length
      }
      onlyOneImage && (nextIndex = 0)
      playIndex % 2 === 0 && (this.firstIndex = nextIndex)
      playIndex % 2 !== 0 && (this.lastIndex = nextIndex)
      return (this.playIndex += 1)
    }
  }
}
</script>
