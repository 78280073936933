var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cameraMonitor ov-h", style: _vm.style }, [
    _c("div", [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://media.hesiling.com/resource/20210618154806ny8vZDl57bZGc9b.png",
          alt: "",
        },
      }),
      _c("p", [_vm._v(_vm._s(_vm.data.name))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }