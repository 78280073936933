var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 h-100 of-h", style: _vm.style }, [
    _c(
      "div",
      {
        staticClass: "flex transparent text-nowrap align-center h-100",
        style: _vm.FontStyle,
      },
      [_vm._v(_vm._s(_vm.date))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }