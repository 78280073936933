import H5AnimeHandler from 'libs/H5AnimeHandler'
export default {
  props: { playAnimationSwitch: { type: Boolean } },
  data () {
    return {
      datasIndex: 0,
      isStopAnime: true,
      handleLeaveAnime: [],
      handleEnterAnime: [],
      tdAnimeList: []
    }
  },
  watch: {
    playAnimationSwitch (val) {
      const { tableCellDatas } = this.data
      if (tableCellDatas.length === 1) return // TODO: 表格只有1页数据不进行动画
      if (val) this.isStopAnime = false
      if (val) this.play()
      if (!val) this.stop()
    }
  },
  methods: {
    play () {
      const { isStopAnime } = this
      this.tdAnimeList.length === 0 && (this.tdAnimeList = this.getAnimeElement())
      if (this.tdAnimeList.length <= 0 || isStopAnime) return
      this.isStopAnime = false
      const leaveOptions = this.leaveAnimeOptions()
      this.handleLeaveAnime = Array.from(this.tdAnimeList, (td) => {
        return H5AnimeHandler.playAnime({ ...leaveOptions, node: td })
      })
      const { tableCellDatas } = this.data
      const { datasIndex } = this
      this.handleLeaveAnime[0].onfinish = () => {
        let nextIndex = datasIndex + 1
        datasIndex + 1 >= tableCellDatas.length && (nextIndex = 0)
        this.datasIndex = nextIndex
        this.playenterAnime()
      }
    },
    stop () {
      const { handleLeaveAnime, handleEnterAnime } = this
      handleLeaveAnime.map((anime) => { anime.cancel() })
      handleEnterAnime.map((anime) => { anime.cancel() })
      Object.assign(this.$data, this.$options.data())
    },
    playenterAnime () {
      const { tdAnimeList, isStopAnime } = this
      if (tdAnimeList.length <= 0 || isStopAnime) return
      const that = this
      this.handleEnterAnime = Array.from(tdAnimeList, (td) => {
        return H5AnimeHandler.playAnime({ ...this.enterAnimeOptions(), node: td })
      })
      this.handleEnterAnime[0].onfinish = () => {
        that.play()
      }
    },
    leaveAnimeOptions () {
      const { tableLeaveAnime, tableAnimePlayTime, tableAnimeShowTime } = this.data
      const duration = tableAnimePlayTime / 2 * 1000
      const anime = tableLeaveAnime.animeClass || 'fadeOut'
      const delay = tableAnimeShowTime * 1000
      return { anime, options: { duration, delay, fill: 'none' } }
    },
    enterAnimeOptions () {
      const { tableEnterAnime, tableAnimePlayTime } = this.data
      const duration = tableAnimePlayTime / 2 * 1000
      const anime = tableEnterAnime.animeClass || 'fadeIn'
      const delay = 0
      return { anime, options: { duration, delay, fill: 'none' } }
    },
    getAnimeElement () { return this.$el.getElementsByClassName('content isAnime') }

  }
}
