import { toPlayAnime } from 'libs/DocumentAnimates.js'
const GET_ANIME_CONFIG = () => {
  return {
    duration: 1000,
    delay: 0,
    direction: 'normal',
    fill: 'forwards'
  }
}
class H5AnimeHandler {
  constructor () {
    this.normalHandleList = []
    this.splitTextSet = new Set()
    this.playedNumber = 0 // 播放次数
    this.pagehandle = {}
  }

  init (pagehandle) {
    this.pagehandle = pagehandle
  }

  end () {
    this.playedNumber = 0 // 播放次数
    this.normalHandleList.forEach((item) => {
      item.pause()
      item.cancel()
    })
    // item.cancel() // 取消动画 会使元素恢复默认状态
    // item.commitStyles() // 即使已删除动画，也将动画的最终样式状态提交给要动画的元素。这将导致最终样式状态以属性内的style属性形式写入正在动画的元素中。
  }

  pause () {
    this.normalHandleList.forEach(item => {
      item.pause()
    })
  }

  playAnime ({ id, node, anime = 'original', options } = {}) {
    if (!id && !Node) return new Error('请传入 id 或 节点')
    const $options = GET_ANIME_CONFIG()
    options && Object.assign($options, options)
    // this.pagehandle.$log.log({ id, node, anime, options })
    return toPlayAnime({ id, node, anime, options: $options })
  }

  layersAnime (animeList) {
    if (!animeList) return
    animeList.forEach(async (item, i) => {
      const { anime, type, onlyKey, options, loop } = item
      let animations = []
      const parms = { onlyKey, anime, options, loop }
      type === 'text' && (animations = await this._playTextANime(parms))
      type !== 'text' && (animations = this._playNormalANime(parms))
      this.normalHandleList.push(...animations)
    })
  }

  _playNormalANime ({ onlyKey, anime, options, loop }) {
    const animation = this._isLoopPlay({ options: { id: onlyKey, anime, options }, loop, type: 'normal' })
    return [animation]
  }

  async _playTextANime ({ onlyKey, anime, options, loop }) {
    const textNode = await this._splitText(onlyKey)
    const nodeList = await this._getTextAnimeNodeList({ anime, onlyKey, options, textNode })
    return nodeList.map((item, index) => {
      return this._isLoopPlay({ options: item, loop, length: nodeList.length - 1, index, type: 'text' })
    })
  }

  // 切割文字
  _splitText (onlyKey) {
    return new Promise((resolve, reject) => {
      const domNode = document.getElementById(onlyKey)
      const children = domNode.children[0]
      if (this.splitTextSet.has(onlyKey)) return resolve(children)
      const textArr = children.innerText.split('')
      children.innerText = ''
      textArr.forEach(word => {
        if (word === '\n') {
          children.appendChild(document.createElement('br'))
        } else {
          const span = document.createElement('span')
          span.innerText = word
          children.appendChild(span)
        }
      })
      this.splitTextSet.add(onlyKey)
      resolve(children)
    })
  }

  // 文字动画节点数组 返回 处理好的数组动画参数
  _getTextAnimeNodeList ({ anime, options, textNode }) {
    const { duration, endDelay } = options
    const parent = textNode.parentElement.style
    const opacity = parent.opacity === '0' ? '0' : '1'
    opacity === '0' && this.playAnime({ node: textNode.parentElement, anime: 'fadeIn', options: { duration: 50 } })
    const childNodes = textNode.childNodes
    const dura = Math.ceil((duration) / childNodes.length)
    const nodeList = []
    childNodes.forEach((span, i) => {
      span.style.opacity = opacity
      span.style.textDecoration = textNode.style.textDecoration
      span.classList.add('span-anime')
      const EndDelay = (childNodes.length - i) * dura + endDelay
      const Options = Object.assign({}, options, { duration: dura, delay: i * dura, endDelay: EndDelay })
      nodeList.push({ node: span, anime, options: Options })
    })
    return nodeList
  }

  // 判断重复播放动画的函数
  // options 动画参数 loop 循环次数 type 动画类型 length 文字动画数组长度 index 文字动画当前节点索引
  _isLoopPlay ({ options, loop, length, index, type }) {
    let playNumber = 0
    const iterations = loop === 0
    const once = loop === 1
    const animation = this.playAnime(options)
    animation.onfinish = () => {
      if (iterations) return animation.play()
      type === 'text' && length === index && this._layersAnimeEnd()
      type !== 'text' && this._layersAnimeEnd()
      playNumber += 1
      if (playNumber >= loop || once) return
      animation.play()
    }
    return animation
  }

  _layersAnimeEnd (animeList) {
    this.playedNumber += 1
  }
}

let instance = null
const H5AnimeHandleInstance = () => {
  if (instance) return instance
  instance = new H5AnimeHandler()
  return instance
}

export default H5AnimeHandleInstance()
