<template>
  <div :style="style" class="ov-h">
    <img class="w-100" :src="data.url" alt="">
  </div>
</template>
<script>
export default {
  name: 'Order-Details',
  props: { data: { type: Object } },
  computed: {
    style () {
      const { width, height } = this.data
      return {
        width: width + 'px',
        height: height + 'px'
      }
    }
  },
  methods: {
  }
}
</script>
<style scoped lang='stylus' rel='stylesheet/stylus'></style>
