<!--
 * @Author: 伽蓝
 * @Date: 2020-07-03 16:01:51
 * @LastEditTime: 2022-11-14 20:39:23
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web/src/views/Home.vue
 * @代码不规范,调试泪两行
-->
<template>
  <pages
    v-loading="loading"
    ref="page"
    :pages-data="pages"
    :bl="bl"
    :base="base"
    :width="width"
    :height="height"
    :menuData="menuData"
  ></pages>
</template>
<script>
import Pages from 'coms/Pages'
import CreateFont from 'tools/CreateFont'
import { apiGetDynamicData } from '../api/smartDynamicMenu'
export default {
  name: 'home',
  components: {
    Pages
  },
  data() {
    return {
      pages: [],
      base: {},
      fontFace: {},
      bl: 1,
      width: 0,
      height: 0,
      loading: true,
      menuData: null
    }
  },
  watch: {
    $route: {
      handler(val, old) {
        this.getData(val.query)
        const { width, height } = val.query
        if (!width || !height) return
        Object.assign(window, { innerHeight: height, innerWidth: width })
      },
      immediate: true
    }
  },
  methods: {
    async getData(params) {
      const { id, type, date, name } = params
      Object.assign(this.$globle, params)
      const { getMpViewData, getTemplateData, getContentData } = this.$api.preview
      let data = {}
      try {
        switch (type) {
          case 'mp':
            data = await getMpViewData({ date, name })
            break
          case 'template':
            data = await getTemplateData(id)
            break
          case 'content':
            data = await getContentData(id)
            if (data.data.pages.some(page => page.layers.some(layer => layer.type === 'smartDynamicMenu' && !layer.menuData))) {
              const menuData = await apiGetDynamicData(id)
              if (menuData && menuData.data && menuData.data.lists) {
                this.menuData = menuData.data.lists
              }
            }
            break
        }
      } catch (error) {
        return this.$message({
          message: error,
          type: 'error',
          showClose: true,
          duration: 5000
        })
      }
      this.$log.log('节目数据', data)
      console.log('节目数据', data)
      Object.assign(this, { ...data.data, loading: false })
      await this.setFont()
      await this.initWebView()
      await this.$refs.page.play()
    },
    setFont() {
      const { fontFace } = this
      for (const key in fontFace) {
        CreateFont.createFontFamly({ fontFamily: key, url: fontFace[key].url })
      }
    },
    // 初始化节目数据
    initWebView() {
      const { arrangementCol, arrangementRow, screenDirection } = this.base
      const { screenWidth, screenHeight } = this.base
      const { innerHeight, innerWidth } = window
      console.log({ innerHeight, innerWidth })
      const designWidth = arrangementRow * screenWidth // 设计宽度
      const designHeight = screenHeight * arrangementCol
      const bl =
        screenDirection === '16/9'
          ? Number((innerWidth / designWidth).toFixed(4))
          : Number((innerHeight / designHeight).toFixed(4))
      Object.assign(this, { bl, width: designWidth, height: designHeight })
    }
  }
}
</script>
