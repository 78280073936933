var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "td-content", style: { ..._vm.TdStyle } }, [
    _c("span", {
      staticClass: "content flex",
      class: { isAnime: _vm._isAnimeTD() },
      style: {
        textDecoration: _vm.TdStyle.textDecoration,
        alignItems: _vm.TdStyle.alignItems,
        justifyContent: _vm.TdStyle.justifyContent,
        padding: _vm.TdStyle.padding,
      },
      domProps: { innerHTML: _vm._s(_vm.cellData) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }