<!--
 * @Author: 伽蓝
 * @Date: 2020-12-15 09:42:48
 * @LastEditTime: 2020-12-17 17:41:36
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web-view/src/components/mod/Snowman.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div>
    <img :src="data.url" :style="style" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { toPlaySpecial } from '@/libs/DocumentAnimates'
export default {
  name: 'snowman',
  data () {
    return {
      animeHandle: undefined
    }
  },
  props: {
    data: { type: Object },
    playAnimationSwitch: { type: Boolean, default: false }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.play()
      if (!val) this.stop()
    }
  },
  computed: {
    style () {
      const { width, height, transform } = this.data
      return {
        width: `${width}px`,
        height: `${height}px`,
        transform
      }
    }
  },
  methods: {
    ...mapActions(['toChangeLayer']),
    play () {
      const options = {
        duration: 1600,
        iterations: Infinity
      }
      this.animeHandle = toPlaySpecial({ node: this.$el, anime: 'snowmanSwing', options })
    },
    stop () {
      this.animeHandle && this.animeHandle.cancel()
      this.animeHandle = undefined
    }
  }
}
</script>
