<!--
 * @Author: hsl-lmx
 * @Date: 2021-06-18 15:00:27
 * @LastEditTime: 2021-06-22 19:54:10
 * @LastEditors: Please set LastEditors
 * @Description: 直播组件
 * @FilePath: \h5-web\src\components\mod\CameraMonitor.vue
-->
<template>
    <div :style="style" class="cameraMonitor ov-h">
        <div>
            <img class="icon" src="https://media.hesiling.com/resource/20210618154806ny8vZDl57bZGc9b.png" alt="">
            <p>{{data.name}}</p>
        </div>
    </div>
</template>
<script>
export default {
  name: 'cameraMonitor',
  props: { data: { type: Object } },
  computed: {
    style () {
      const { width, height, style, fontFamily } = this.data
      return {
        width: width + 'px',
        height: height + 'px',
        fontSize: style.fontSize + 'px',
        fontFamily
      }
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.cameraMonitor {
    background: url('https://media.hesiling.com/resource/20210618154806M1PvloRAmEMglkf.png')
    background-repeat: no-repeat;
    background-size: cover;
    display: flex
    justify-content: center
    align-items: center
    text-align: center
}
</style>
