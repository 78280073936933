<!--
 * @Author: 伽蓝
 * @Date: 2021-03-08 10:43:44
 * @LastEditTime: 2021-03-22 16:06:57
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web/src/components/mod/VirtualSales.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
  <div class="flex no-wrap" :style="{ ...style }">
    <span :id="data.componentID" class="no-wrap"></span>
  </div>
</template>

<script>
import CountUp from '@/libs/countUp'

export default {
  components: {},
  name: 'virtual-sales',
  props: {
    data: { type: Object },
    playAnimationSwitch: { type: Boolean, default: false }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.init()
      if (!val) this.close()
    }
  },
  // this.isVirtualSales ? this.end : this.start
  data () {
    return {
      salesVolume: 0,
      countUp: undefined,
      time: 3,
      timer: Number,
      id: ''
    }
  },
  computed: {
    style () {
      const { width, height, style } = this.data
      const { fontSize, letterSpacing, textAlign } = style
      const { fontFace, fontFamily } = this.data
      const justifyContent =
        textAlign === 'left'
          ? 'flex-start'
          : textAlign === 'right'
            ? 'flex-end'
            : textAlign
      const style2 = {
        fontFace,
        fontFamily,
        width: width + 'px',
        height: height + 'px',
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px',
        justifyContent
      }
      return Object.assign({}, { ...style }, style2)
    },
    isVirtualSales () {
      return this.data.type === 'virtualSales'
    },
    start () {
      return this.data.configuration.start
    },
    end () {
      return this.data.configuration.end
    }
  },
  created () {
    this.$init()
  },
  methods: {
    init () {
      const { start } = this.data.configuration
      this.updateSales()
      this.timer = setInterval(() => {
        if (this.isVirtualSales) {
          this.salesVolume += parseInt(Math.random() * 100)
        } else {
          this.salesVolume -= parseInt(Math.random() * start / 100)
        }
        if (!this.playAnimationSwitch) clearInterval(this.timer)
        this.updateSales(this.salesVolume)
      }, this.time * 1000)
    },
    $init () {
      this.salesVolume = this.start
    },
    close () {
      clearInterval(this.timer)
      Object.assign(this.$data, this.$options.data())
      this.$init()
    },
    updateSales (number) {
      const { countUp } = this
      if (!countUp) {
        var options = {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          duration: 2,
          startVal: this.salesVolume
        }
        this.countUp = new CountUp(this.$el, 5000, options)
      } else {
        this.countUp.update(number)
      }
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.no-wrap {
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
}
</style>
