<!--
 * @Author: 伽蓝
 * @Date: 2020-07-03 16:01:51
 * @LastEditTime: 2021-01-06 14:26:59
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web-view/src/components/OnePageVideoMuted.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div class="po-f vw-100 vh-100" :class="$globle.type === 'mp'? '':'z-index-l'" v-show="videoMuted" @click="open">
  </div>
</template>
<script>
export default {
  data () {
    return { videoMuted: true }
  },
  props: {
    pagesData: {
      type: Array,
      default () {
        return []
      }
    },
    openVideoSound: {
      type: Function
    }
  },
  watch: {
    pagesData (val) {
      if (val.length > 0) {
        this.getVideoMuted()
      }
    }
  },
  methods: {
    getVideoMuted () {
      const { pagesData } = this
      const videoMuted = pagesData.some((item) => {
        return item.layers.some((item) => {
          return item.type === 'video'
        })
      })
      Object.assign(this, { videoMuted })
      const { type } = this.$globle
      videoMuted && this.$notify({
        title: type === 'mp' ? '暂停播放' : '静音播放',
        message: type === 'mp' ? '请点击页面任意位置开始播放视频' : '请点击页面任意位置开启声音',
        duration: 0,
        type: 'warning'
      })
    },
    open () {
      const { type } = this.$globle
      this.$notify({ title: '成功', message: type === 'mp' ? '视频开始播放' : '声音已开启', type: 'success', duration: 1000 })
      window.videoHandle && window.videoHandle.play()
      this.$notify.closeAll()
      this.videoMuted = false
      this.openVideoSound(false)
    }
  }
}
</script>
