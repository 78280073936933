
<template>
  <div class="smartDynamicMenu"  :style="style">
    <PictureCollection
      data-name="pictureStyle"
      :onlyKey="data.onlyKey"
      :class="modal ? 'moveable-target' : 'moveable-item'"
      v-if="data.pictureMenu"
      :style="data.pictureStyle"
      :data="data"
      :info="info"
    ></PictureCollection>
    <TextCollection
      data-name="textStyle"
      :onlyKey="data.onlyKey"
      :class="modal ? 'moveable-target' : 'moveable-item'"
      v-if="data.textMenu"
      :style="data.textStyle"
      :data="data"
      :info="info"
    ></TextCollection>
  </div>
</template>
<script>
import TextCollection from "./TextCollection.vue";
import PictureCollection from "./PictureCollection.vue";
import { compareVersion, defaultTo } from '../../../tools/utils';
import { formatPrice, getSum, getTimesVal, numFormatter2 } from '../../../tools/numberCalc';

export default {
  components: { TextCollection, PictureCollection },
  props: { data: { type: Object }, modal: { type: Boolean, default: false }, menuData: Object },
  computed: {
    isVersion3() {
      return compareVersion(this.data.version, '3.0.0') >= 0
    },
    style() {
      let { fontFace, fontFamily, width, height } = this.data;
      return {
        width: width + "px",
        height: height + "px",
        fontFace,
        fontFamily,
      };
    },
    info() {
      const { menuData, menuCombinationName, imageGroup, repeatGoods } = this.data
      let list = (menuData || this.menuData || {})[menuCombinationName] || []
      // 兼容历史节目数据
      if (!Array.isArray(list)) {
        list = [
          ...(list.text || []),
          ...(list.images || []).map(item => ({ ...item, images: [{ image: item.image }] }))
        ]
      }
      const result = list.reduce((acc, item) => {
        const cur = {
          ...item,
          matchedSkuList: this.filterMatchedSkuList(item)
        }
        if (cur.images && cur.images.length > 0) {
          // imageGroup无值则为历史数据, 视为使用默认组别图片, 取第一个
          const useImage = imageGroup ? cur.images.find(item => item.group_id === imageGroup) : cur.images[0]
          if (useImage && useImage.image) {
            acc.images.push({ ...cur, image: useImage.image })
          }
          // 选择的组别未配置图片, 或者开启了重复商品, 则展示到文字菜单中
          if (!useImage || !useImage.image || repeatGoods) {
            acc.text.push(cur)
          }
        } else {
          acc.text.push(cur)
        }
        return acc
      }, { images: [], text: [] })
      result.text = this.sortTextMenus(result.text)
      return result
    }
  },
  methods: {
    filterMatchedSkuList({ units, foodId }) {
      if (!this.isVersion3) return
      const { matchPriceRules } = this.data
      if (!units || !matchPriceRules || !matchPriceRules.length) return []
      return Object.keys(units)
        // 按规格匹配规则过滤
        .filter(key => {
          return matchPriceRules.some(rule => {
            return key.split(',').some(specName => rule.is_accurate === 1 ? rule.unit_name === specName : specName.includes(rule.unit_name))
          })
        })
        .map(item => {
          const sku = {
            specName: item,
            salePrice: defaultTo(units[item]),
          }
          sku.discountPrice = this.getDiscountPrice(foodId, sku)
          return sku
        })
    },
    getDiscountPrice(foodId, { specName, salePrice }) {
      const { discountPriceConfig, smartMenuCostUnits } = this.data
      if (!discountPriceConfig) return ''
      const discountInfo = smartMenuCostUnits.find(item => item.goodsId === foodId)
      if (!discountInfo || !discountInfo.units) return ''
      const matched = discountInfo.units.find(item => specName.split(',').includes(item.unitName))
      if (matched) {
        const { disType, dis } = matched
        return formatPrice(numFormatter2(disType === 1 ? getTimesVal(salePrice, dis) : getSum(salePrice, dis)))
      }
      return ''
    },
    sortTextMenus(menus) {
      const { textSortAttr, textSortType, textPriceSpecs } = this.data
      if (!textSortAttr || !textSortType) return menus
      const factor = textSortType === 'ASC' ? 1 : -1
      let sortFn
      if (textSortAttr === 'minPrice') {
        sortFn = this.getSortFn(this.getMinPrice, factor)
      } else if (textSortAttr === 'maxPrice') {
        sortFn = this.getSortFn(this.getMaxPrice, factor)
      } else if (textPriceSpecs && textPriceSpecs.length) {
        sortFn = this.getSortFn(this.getSpecPrice(textSortAttr), factor)
      }
      if (!sortFn) return menus
      return [...menus].sort(sortFn)
    },
    getSortFn(valueFn, factor) {
      // 无价格的需排在最后
      const defaultValue = factor * Infinity
      return (a, b) => factor * ((valueFn(a) || defaultValue) - (valueFn(b) || defaultValue))
    },
    getMinPrice(info) {
      if (!info.matchedSkuList.length) return 0
      return Math.min(...info.matchedSkuList.map(item => +item.salePrice))
    },
    getMaxPrice(info) {
      if (!info.matchedSkuList.length) return 0
      return Math.max(...info.matchedSkuList.map(item => +item.salePrice))
    },
    getSpecPrice(spec) {
      return (info) => {
        const menu = info.matchedSkuList.find(item => item.specName.split(',').includes(spec))
        return menu ? +menu.salePrice : 0
      }
    }
  }
};
</script>
<style scoped lang="scss">

.smartDynamicMenu{
  position: relative;
  pointer-events: none;
  .moveable-target,.moveable-item{
    pointer-events: auto;
    position: absolute !important;
    top: 0;
    left: 0;
  }
}
</style>
