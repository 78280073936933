var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex", style: { ..._vm.style } }, [
    _c("span", [
      _vm._v(_vm._s(_vm.data.dataField || "动态数据组件" + _vm.data.index)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }