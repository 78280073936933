<!--
 * @Author: 伽蓝
 * @Date: 2022-11-07 23:21:54
 * @LastEditTime: 2022-11-21 22:46:42
 * @LastEditors: 伽蓝
 * @FilePath: /pc-h5/src/components/work/mod/MarketingMix.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
  <div :class="data.pictureMenuClass">
    <div class="img-wrap po-r" :style="pictureImgSpacingTop">
      <img class="img-cover" :src="info.image" :style="pictureImageStyle" />
    </div>
    <div class="content-wrap" :style="pictureImgSpacingLeft">
      <div class="name" :style="nameFontStyle">
        <p class="flex align-center po-r" :class="{'flex-wrap': isNameVerticalAlign}" :style="foodNameStyle">
          <IconWrap
            v-if="iconInfo.beforeName.length > 0"
            position="before-name"
            :list="iconInfo.beforeName"
            :vertical="isNameVerticalAlign"
          ></IconWrap>
          <span v-if="info.aliasName" v-html="transformHtml(info.aliasName)"></span>
          <span v-else>{{ info.foodName }}</span>
          <IconWrap
            v-if="iconInfo.afterName.length > 0"
            position="after-name"
            :list="iconInfo.afterName"
            :vertical="isNameVerticalAlign"
          ></IconWrap>
        </p>
      </div>
      <div :class="priceLayoutClass" :style="priceStyle">
        <div class="price-item" :style="priceItemStyle" v-for="(item, i) in priceList" :key="i">
          <IconWrap
            v-if="item.beforeIconList.length > 0"
            position="before-price"
            :list="item.beforeIconList"
            :vertical="isNameVerticalAlign"
          ></IconWrap>
          <template v-if="item.price !== ''">
            <span class="po-r" :style="priceFontStyle">
              <span v-show="data.showPriceSign">¥</span>
              <span>{{ item.price }}</span>
              <IconWrap
                v-if="iconInfo.overPrice.length > 0"
                position="over-price"
                :list="iconInfo.overPrice"
                :vertical="isNameVerticalAlign"
              ></IconWrap>
              <IconWrap
                v-if="item.afterIconList.length > 0"
                position="after-price"
                :list="item.afterIconList"
                :vertical="isNameVerticalAlign"
              ></IconWrap>
            </span>
          </template>
        </div>
      </div>
    </div>
    <div ref="emElement" class="emWidth" :style="[nameFontStyle]"></div>
  </div>
</template>

<script>
import IconWrap from './IconWrap.vue'
import { defaultTo, compareVersion, transformHtml } from 'tools/utils'
import { DEAULT_SMART_ICON_STYLE } from 'libs/BaseConfig'

export default {
  name: "PictureItem",
  props: {
    data: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      oneLetterWidth: 20
    }
  },
  computed: {
    isVersion3() {
      return compareVersion(this.data.version, '3.0.0') >= 0
    },
    isNameVerticalAlign() { // 是否名称竖排(名称宽度不大于一个字的宽度)
      return this.data.nameWidth <= this.oneLetterWidth
    },
    priceList() {
      let priceList = []
      const { matchedSkuList } = this.info
      if (matchedSkuList) {
        const sort = 'ASC'
        const { priceMedium, priceBig } = this.iconInfo
        const priceIconList = sort === 'DESC' ? [priceBig, priceMedium] : [priceMedium, priceBig]
        const priceSet = new Set()
        priceList = [...matchedSkuList]
          .sort((a, b) => sort === 'DESC' ? b.salePrice - a.salePrice : a.salePrice - b.salePrice)
          .reduce((acc, cur) => {
            if (!priceSet.has(cur.salePrice)) {
              acc.push(cur.salePrice)
              priceSet.add(cur.salePrice)
              }
              return acc
              }, [])
          .slice(0, 2)
          .map((price, index) => {
            const beforeIconList = []
            if (index === 0 || this.data.pictureMenuClass === 'img-left') {
              beforeIconList.push(...this.iconInfo.beforePrice)
            }
            if (priceIconList[index] && price !== '') {
              beforeIconList.push(priceIconList[index])
            }
            return {
              price,
              beforeIconList,
            }
          })
      } else if (this.data.isYiHeTang) {
        priceList = Object.entries(this.info.units || {})
          .map(([spec, price]) => ({ price: defaultTo(price), spec }))
          .sort((a, b) => a.price - b.price)
          .reduce((acc, item, index) => {
            // 最多取两个价格; 价格重复的忽略
            if (acc.length > 1 || (acc[0] && item.price === acc[0].price)) return acc
            let beforeIconList = []
            if (index === 0 || this.data.pictureMenuClass === 'img-left') {
              beforeIconList = [...this.iconInfo.beforePrice]
            }
            const { priceBig, priceMedium } = this.iconInfo
            if (item.spec === '大杯' && priceBig) {
              beforeIconList.push(this.iconInfo.priceBig)
            } else if (item.spec === '中杯' && priceMedium) {
              beforeIconList.push(this.iconInfo.priceMedium)
            }
            acc.push({
              beforeIconList,
              price: item.price,
            })
            return acc
          }, [])
      } else if (this.isXL) {
        priceList = [{
          price: defaultTo(this.info.priceBig || this.info.priceMedium),
          beforeIconList: this.iconInfo.beforePrice,
        }]
      } else {
        priceList = ['priceMedium', 'priceBig'].map((key, index) => {
          let list = []
          const price = defaultTo(this.info[key])
          if (index === 0 || this.data.pictureMenuClass === 'img-left') {
            list = [...this.iconInfo.beforePrice]
          }
          if (this.iconInfo[key] && price !== '') {
            list.push(this.iconInfo[key])
          }
          return {
            price,
            beforeIconList: list,
          }
        })
      }
      let lastHasPriceIndex = -1
      for (let i = priceList.length - 1; i >= 0; i--) {
        if (priceList[i].price !== '') {
          lastHasPriceIndex = i
          break
        }
      }
      return priceList.map((item, index) => {
        // 价格竖排或者是最后一个有值的价格时, 展示所有价格后图标
        if (this.data.pictureMenuClass === 'img-left' || index === lastHasPriceIndex) {
          item.afterIconList = [...this.iconInfo.afterPrice]
        } else {
          item.afterIconList = []
        }
        return item
      })
    },
    iconInfo() {
      const keyMap = {
        'before-name': 'beforeName',
        'after-name': 'afterName',
        'before-price': 'beforePrice',
        'after-price': 'afterPrice',
        'over-price': 'overPrice',
      }
      const initial = {
        beforeName: [],
        afterName: [],
        beforePrice: [],
        afterPrice: [],
        overPrice: [],
        priceMedium: null,
        priceBig: null
      }
      return this.data.menuIconList.reduce((pre, item) => {
        // 无applyRange的说明是历史数据, 按需要展示处理
        if (item.url && (!item.applyRange || item.applyRange.picture)) {
          const cur = JSON.parse(JSON.stringify(item))
          cur.style = this.getStyle(cur)
          if (cur.isSystem && cur.id !== 'sellOut') {
            pre[cur.id] = cur
          } else if (keyMap[cur.showPosition] && (this.info.attributes || []).includes(cur.name)) {
            pre[keyMap[cur.showPosition]].push(cur)
          }
        }
        return pre
      }, initial)
    },
    nameFontStyle() {
      const style = this.data.pictureNameStyle || this.data.style
      let { fontSize, letterSpacing } = style
      return {
        ...style,
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px',
      }
    },
    priceFontStyle() {
      const style = this.data.picturePriceStyle || this.data.style
      let { fontSize, letterSpacing } = style
      return {
        ...style,
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px',
      }
    },
    foodNameStyle() {
      let { nameWidth } = this.data
      let width = nameWidth || 200
      if (!this.isNameVerticalAlign) {
        width += this.iconInfo.afterName.reduce((sum, icon) => sum + icon.style.width, 0)
        if (this.iconInfo.afterName.length > 0) {
          // 图标之间的间距 + 图标和名称的间距
          width += (this.iconInfo.afterName.length - 1) * 8 + 2
        }
      }
      return {
        width: width + 'px',
      }
    },
    pictureImageStyle() {
      if (!this.data.pictureImageStyle) return {}
      const { width, height, left, top } = this.data.pictureImageStyle
      return {
        position: 'absolute',
        left: left + 'px',
        top: top + 'px',
        width: width + 'px',
        height: height + 'px',
        maxWidth: 'initial',
        maxHeight: 'initial',
        objectFit: 'initial'
      }
    },
    // 调整 图片和文字之间的间距
    pictureImgSpacingTop() {
      let { pictureImgSpacing, pictureMenuClass } = this.data
      if (pictureMenuClass == 'img-top') {
        return {
          marginBottom: pictureImgSpacing + 'px'
        }
      }
      if (pictureMenuClass === 'img-bottom') {
        return {
          marginTop: pictureImgSpacing + 'px'
        }
      }
      return {}
    },
    // 调整 图片和文字之间的间距
    pictureImgSpacingLeft() {
      let { pictureImgSpacing, pictureMenuClass } = this.data
      if (pictureMenuClass == 'img-left' || pictureMenuClass == 'img-left-with-price-h') {
        return {
          marginLeft: pictureImgSpacing + 'px',
          justifyContent: this.isNameVerticalAlign ? 'flex-start' : 'center'
        }
      }
      return {}
    },
    priceLayoutClass() {
      return this.data.pictureMenuClass === 'img-left-with-price-h' ? 'price-horizontal' : 'price-wrap'
    },
    priceStyle() {
      return { marginLeft: this.data.picturePriceOffset + 'px' }
    },
    priceItemStyle() {
      const { pictureMenuClass, pictureSpacingP, showPriceSign } = this.data
      // 调整价格之间的间距
      return {
        [pictureMenuClass === 'img-left' ? 'marginTop' : 'marginLeft']: pictureSpacingP + 'px',
        minWidth: (showPriceSign ? 35 : 24) + 'px',
      }
    },
    isXL() {
      return this.data.menuCombinationName == '小料'
    }
  },
  methods: {
    getStyle(item) {
      if (!item.applyRange) return {}
      const { picture } = item.applyRange
      return picture[this.rowIndex] || DEAULT_SMART_ICON_STYLE
    },
    transformHtml
  },
  components: { IconWrap },
  mounted() {
    this.oneLetterWidth = this.$refs.emElement.offsetWidth || 20
  }
};
</script>

<style scoped lang="scss">
.emWidth{
  width: 1em;
  opacity: 0;
  position: absolute;
}
div{
  box-sizing: border-box;
}
p, span, div{
  font-weight: inherit;
}
.img-cover{
  width: auto;
  height: auto;
  max-width: 180px;
  max-height: 180px;
  object-fit: contain;
  font-size: 0;
}
.img-left,
.img-left-with-price-h{
  width: 434px;
  height: 180px;
  display: flex;

  .img-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    width: 180px;
    flex-basis: 180px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .content-wrap{
    margin-left: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .name{
      display: flex;
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      color: #333333;
    }
    .price-wrap{
      margin-top: 10px;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: column;
      .price-item{
        margin-bottom: 6px;
        &:last-child{
          margin-bottom: 0;
        }
        &:first-child{
          margin-top: 0 !important;
        }
      }
    }
    .price-horizontal{
      margin-top: 10px;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      .price-item{
        &:first-child{
          margin-left: 0 !important;
        }
      }
    }
  }
}
.img-top,
.img-bottom{
  width: 180px;
  max-height: 344px;
  display: flex;
  flex-direction: column;

  .img-wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    height: 180px;
    width: 180px;
    flex-basis: 180px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 12px;
  }
  .content-wrap{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .name{
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      color: #333333;
      height: 24px;
      max-height: 48px;
      line-height: 24px;
      &.center{
        text-align: center;
      }
    }
    .price-wrap{
      margin-top: 20px;
      width: 100%;
      height: 20px;
      display: flex;
      .price-item{
        &:first-child{
          margin-left: 0 !important;
        }
      }
    }
  }
}
.img-bottom{
  flex-direction: column-reverse;
}
.price-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-height: 20px;
  >span{
    line-height: 20px;
    font-size: 18px;
  }
}
</style>
