<template>
  <div class="po-a of-s vw-100 vh-100 opacity-image">
    <pages-layers class="po-a t-0 l-0 of-h origin-l-t" :class="$globle.type === 'mp' ?'disable-mouse-events':''"
      :style="{...pageStyle,zIndex:pagesData.length-i}" :id="`page_${i}`" v-for="(page,i) in pagesData"
      :key="page.onlyKey || page.key" :page-index="i" :current-index="currentIndex" :page="page"
      :video-muted="videoMuted" :width="width" :menuData="menuData"></pages-layers>
    <one-page-video-muted :pages-data="pagesData" :open-video-sound="openVideoSound"></one-page-video-muted>
  </div>
</template>
<script>
import PagesLayers from './PagesLayers'
import H5AnimeHandler from 'libs/H5AnimeHandler.js'
import H5AnimeTimeLine from 'libs/H5AnimeTimeLine.js'
import OnePageVideoMuted from 'coms/OnePageVideoMuted'
export default {
  components: { PagesLayers, OnePageVideoMuted },
  data () {
    return {
      currentIndex: -1,
      animes: [],
      transformlist: [],
      videoMuted: true
    }
  },

  props: {
    pagesData: {
      type: Array
    },
    bl: {
      type: Number
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    menuData: Object
  },
  computed: {
    pageStyle () {
      const { width, height, bl } = this
      const { innerHeight, innerWidth } = window
      let top = (innerHeight - height * bl) / 2 / innerHeight * 100
      let left = (innerWidth - width * bl) / 2 / innerWidth * 100

      top < 0 && (top = 0)
      left < 0 && (left = 0)
      return {
        width: width + 'px',
        height: height + 'px',
        transform: `scale(${bl})`,
        top: top + '%',
        left: left + '%'
      }
    },
    animeNumber () {
      let number = 0
      const { animes } = this
      animes.forEach(item => {
        number += item.loop
      })
      return number
    }
  },
  created () {
    H5AnimeTimeLine.init(this)
    H5AnimeHandler.init(this)
  },
  methods: {
    play () {
      this.pageStart(0)
    },
    pageStart (pageindex = 0) {
      this.$notify({
        title: '成功',
        message: `页面 ${pageindex + 1} 播放开始`,
        type: 'success',
        duration: 1000
      })
      const data = this.pagesData[pageindex]
      const animes = H5AnimeTimeLine.play(data)
      Object.assign(this, { animes })
      this.$set(this, 'currentIndex', pageindex)
    },
    pageEnd () {
      const result = this.isPagePlayEnd()
      if (result) {
        H5AnimeHandler.end()
        this.pageTransformStart()
      }
    },
    pageTransformStart () {
      const { currentIndex, pagesData } = this
      const page = pagesData[currentIndex]
      const id = `page_${currentIndex}`
      const anime =
        currentIndex === pagesData.length - 1
          ? 'original'
          : page.leaveAnime.class // 取页面的动画名字
      const config = { anime, id, options: { duration: 1000 } }
      const animation = H5AnimeHandler.playAnime(config)
      animation.onfinish = () => {
        this.pageTransformEnd()
      }
      this.transformlist.push(animation)
    },
    pageTransformEnd () {
      const { currentIndex, pagesData } = this
      if (pagesData.length - 1 === currentIndex) {
        this.end()
      } else {
        this.pageStart(currentIndex + 1)
      }
    },
    end () {
      this.$set(this, 'currentIndex', -1)
      this.$confirm('节目结束,是否重播?', '提示', {
        confirmButtonText: '重播',
        cancelButtonText: '取消',
        center: true
      })
        .then(() => {
          this.transformlist.map(item => item.cancel())
          const { videoMuted } = this
          Object.assign(this.$data, this.$options.data(), { videoMuted })
          this.play()
        })
        .catch(() => {})
    },
    layersAnime (list) {
      H5AnimeHandler.layersAnime(list)
    },
    // 判断页面是否真的结束
    isPagePlayEnd () {
      const { animeNumber } = this
      const { playedNumber } = H5AnimeHandler
      if (animeNumber >= playedNumber) return true
    },
    openVideoSound (videoMuted) {
      this.videoMuted = false
    }
  }
}
</script>
