
class H5AnimeTimeLine {
  constructor () {
    this.page = null
    this.count = 0
    this.timeline = 0
    this.animes = []
    this.pageHandle = {}
  }

  init (pageHandle) {
    this.pageHandle = pageHandle
  }

  play (page) {
    const animes = this.getAnimes(page)
    const formatAnimes = this.formatAnimes(animes) // 格式化后的动画数据列表
    Object.assign(this, { animes: formatAnimes, page })
    this.pageTimeLine()
    return formatAnimes
  }

  pageTimeLine () {
    this.layersAnime()
    this.timeline = setInterval(() => {
      this.count += 1
      this.pageHandle.count = this.count
      const result = this.isPageTransformTime()
      if (!result) this.layersAnime()
      if (result) {
        clearInterval(this.timeline)
        this.pageHandle.pageEnd()
        this.end()
      }
    }, 100)
  }

  end () {
    this.count = 0
    this.timeline = 0
  }

  isPageTransformTime () {
    const { programTime, leaveAnime } = this.page
    const { duration } = leaveAnime
    const pageEndTime = (programTime - duration) * 1000
    if (this.count * 100 < pageEndTime) return false
    return true
  }

  layersAnime () {
    const { count, animes } = this
    const time = count * 100
    const animePlayList = animes.filter((item) => {
      const { delay } = item
      return delay === time
    })
    if (animePlayList.length === 0) return
    this.pageHandle.layersAnime(animePlayList)
  }

  formatAnimes (animes) {
    return animes.map((item) => {
      // eslint-disable-next-line camelcase
      const { startClass, onlyKey, animeType, anime_type, loop, endDelay, delay, duration } = item
      // eslint-disable-next-line camelcase
      const type = animeType || anime_type
      // const iterations = loop === 0 ? 'Infinity' : loop
      const options = { endDelay: endDelay * 1000, duration: duration * 1000, iterations: 1 }
      const anime = { anime: startClass, onlyKey, delay: delay * 1000, type, options, loop }
      return anime
    })
  }

  getAnimes (page) {
    const animes = []
    const { layers } = page
    layers.forEach((layer, index) => {
      const { animations, onlyKey } = layer
      if (animations.length === 0) return
      animations.forEach(item => {
        item.onlyKey = onlyKey
        animes.push(item)
      })
    })
    return animes
  }
}

let instance = null
const H5AnimeTimeLineInstance = () => {
  if (instance) return instance
  instance = new H5AnimeTimeLine()
  return instance
}

export default H5AnimeTimeLineInstance()
