var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-grey text-black flex-center ov-h", style: _vm.style },
    [_vm._v(" 直播组件 ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }