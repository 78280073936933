<template>
  <div class="flex" :style="{ ...style }">
    <span>{{ data.dataField || "动态数据组件" + data.index }}</span>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'dynamic-data',
  props: {
    data: { type: Object }
  },
  computed: {
    style () {
      const { width, height, style } = this.data
      const { fontSize, letterSpacing, textAlign } = style
      const { fontFace, fontFamily } = this.data
      const justifyContent =
        textAlign === 'left'
          ? 'flex-start'
          : textAlign === 'right'
            ? 'flex-end'
            : textAlign
      const style2 = {
        fontFace,
        fontFamily,
        width: width + 'px',
        height: height + 'px',
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px',
        justifyContent
      }
      return Object.assign({}, { ...style }, style2)
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
