<template>
  <img class="po-a mouse-events" v-if="url" :style="style" :src="url" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    width: {
      type: Number
    }
  },
  computed: {
    style () {
      const bgImg = this.data
      const bl = this.width / bgImg.width
      const width = bgImg.dom_width * bl + 'px'
      const height = bgImg.dom_height * bl + 'px'
      const { left, top } = bgImg
      const transform = `translateX(-${left *
        bl}px) translateY(-${top * bl}px)`
      return { width, height, transform, overflow: 'hidden' }
    },
    url () {
      return this.data.url
    }
  }
}
</script>
