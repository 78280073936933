var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.mpmShow,
          expression: "data.mpmShow",
        },
      ],
      staticClass: "marketing",
    },
    [
      _c(
        "div",
        {
          staticClass: "title bg-1",
          style: {
            ..._vm.beverageNameStyle,
            ..._vm.style,
            ...{ opacity: _vm.data.beverageName ? 1 : 0 },
          },
        },
        [_vm._v(" " + _vm._s(_vm.data.beverageName || ".") + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "desc",
          style: {
            ..._vm.activityNameStyle,
            ..._vm.style,
            ...{ opacity: _vm.data.activityName ? 1 : 0 },
          },
        },
        [_vm._v(" " + _vm._s(_vm.data.activityName || ".") + " ")]
      ),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.img1IsOk,
            expression: "img1IsOk",
          },
        ],
        staticClass: "img-1",
        attrs: { src: _vm.img1 },
        on: {
          load: function ($event) {
            _vm.img1IsOk = true
          },
        },
      }),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.img2IsOk,
            expression: "img2IsOk",
          },
        ],
        staticClass: "img-2",
        attrs: { src: _vm.img2 },
        on: {
          load: function ($event) {
            _vm.img2IsOk = true
          },
        },
      }),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.bgImgIsOk,
            expression: "bgImgIsOk",
          },
        ],
        staticClass: "bg-img",
        attrs: { src: _vm.bgImg },
        on: {
          load: function ($event) {
            _vm.bgImgIsOk = true
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }