<!--
 * @Author: 伽蓝
 * @Date: 2020-12-30 11:21:49
 * @LastEditTime: 2021-03-22 17:28:57
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web/src/components/mod/LivePlay.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div :style="style" class="bg-grey text-black flex-center ov-h">
    直播组件
  </div>
</template>
<script>
export default {
  name: 'live-play',
  props: { data: { type: Object } },
  computed: {
    style () {
      const { width, height, style, fontFamily } = this.data
      return {
        width: width + 'px',
        height: height + 'px',
        fontSize: style.fontSize + 'px',
        fontFamily
      }
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.bg-grey {
  background-color: #8799a3
  color: #fff
}

.text-black {
  color: #333
}

.ov-h {
  overflow: hidden
}
</style>
