<!--
 * @Author: 伽蓝
 * @Date: 2022-11-14 19:50:03
 * @LastEditTime: 2022-11-21 22:52:29
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web/src/components/mod/MarketingMix.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
  <div class="marketing" v-show="data.mpmShow">
    <div class="title bg-1" :style="{ ...beverageNameStyle, ...style, ...{opacity: data.beverageName ? 1 : 0} }">
      {{ data.beverageName || '.' }}
    </div>
    <div class="desc" :style="{ ...activityNameStyle, ...style, ...{opacity: data.activityName ? 1 : 0} }">
      {{ data.activityName || '.' }}
    </div>
    <img class="img-1" v-show="img1IsOk" :src="img1" @load="img1IsOk=true"/>
    <img class="img-2" v-show="img2IsOk" :src="img2"  @load="img2IsOk=true" />
    <img class="bg-img" v-show="bgImgIsOk" :src="bgImg"  @load="bgImgIsOk=true" />
  </div>
</template>

<script>
import ModComsStyleMixin from 'mixin/ModComsStyleMixin'
import H5AnimeHandler from 'libs/H5AnimeHandler'
import md5 from 'js-md5'
export default {
  mixins: [ModComsStyleMixin],
  computed: {
    style () {
      const { fontFamily } = this.data
      return { fontFamily }
    },
    img1 () {
      return this.data.logo_1 + '?random=' + Math.random()
    },
    img2 () {
      return this.data.logo_2 + '?random=' + Math.random()
    },
    bgImg () {
      return this.data.back_image + '?random=' + Math.random()
    }
  },
  data () {
    return {
      beverageNameStyle: { fontSize: '72px', lineHeight: '72px', paddingTop: '0px' },
      activityNameStyle: { fontSize: '72px', lineHeight: '72px' },
      img1IsOk: false,
      img2IsOk: false,
      bgImgIsOk: false
    }
  },
  async created () {
    await this.getMarket()
    await this.getFontSize()
  },
  mounted () {
    this.data.marketingAnimeOpen && this.play()
  },
  methods: {
    play () {
      this.playEnter()
    },
    async getMarket () {
      const { getUsedMarket } = this.$api.marketing
      await getUsedMarket(this.$route.query.id, md5('hsl_2022_' + this.$route.query.id)).then(res => {
        if (res.code === 200) {
          this.data.beverageName = res.data.drink_name
          this.data.activityName = res.data.active_name
          this.data.logo_1 = res.data.logo_1
          this.data.logo_2 = res.data.logo_2
          this.data.back_image = res.data.back_image
          this._createFontFamily(res.data.font_url)
        }
      })
    },
    playEnter () {
      const { enterAnimeClass, speed, duration } = this.data.marketingAnime
      const options = {
        duration: speed * 1000,
        delay: 0,
        direction: 'normal',
        fill: 'forwards',
        endDelay: duration * 1000,
        iterations: 1
      }
      const anime = H5AnimeHandler.playAnime({
        node: this.$el,
        anime: enterAnimeClass,
        options
      })
      anime.onfinish = () => {
        this.playLeave()
      }
    },
    playLeave () {
      const { leaveAnimeClass, speed, duration } = this.data.marketingAnime
      const options = {
        duration: speed * 1000,
        delay: 0,
        direction: 'normal',
        fill: 'forwards',
        endDelay: duration * 1000,
        iterations: 1
      }
      const anime = H5AnimeHandler.playAnime({
        node: this.$el,
        anime: leaveAnimeClass,
        options
      })
      anime.onfinish = () => {
        this.playEnter()
      }
    },
    getFontSize () {
      const { beverageName, activityName } = this.data
      const b = this.getLength(beverageName) / 2
      const a = this.getLength(activityName) / 2
      if (b > 7) {
        this.beverageNameStyle.fontSize = 504 / b + 'px'
        this.beverageNameStyle.lineHeight = 504 / b + 'px'
        this.beverageNameStyle.paddingTop = (72 - 504 / b) + 'px'
      }
      if (a > 7) {
        this.activityNameStyle.fontSize = 504 / a + 'px'
        this.activityNameStyle.lineHeight = 504 / a + 'px'
      }
    },
    getLength (str) {
      if (!str) {
        return 0
      }
      let len = 0
      for (let i = 0; i < str.length; i++) {
        const c = str.charCodeAt(i)
        if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
          len += 1.3
        } else {
          len += 2
        }
      }
      return len
    },
    _createFontFamily (url) {
      const fontFamily = 'OPPOSANS-BOLD'
      const id = fontFamily + '-2'
      const fontFace = this._getFontFace({ fontFamily, url })
      let style = null
      style = document.getElementById(id)
      if (style) {
        style.remove()
      }
      style = document.createElement('style')
      style.id = id
      style.innerText = fontFace
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(style)
    },
    _getFontFace ({ fontFamily, url }) {
      const fontFace1 = `@font-face {font-family: ${fontFamily};src:url(`
      const fontFace2 = ") format('truetype')};"
      return `${fontFace1}'${url}'${fontFace2}`
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.marketing {
  box-sizing: border-box;
  width: 580px;
  height: 450px;
  position: relative;
  padding: 30px 40px;

  .title {
    position: relative;
    font-family: "OPPOSANS-BOLD";
    font-size: 72px;
    color: #000000;
    z-index: 2;
    width: 504px;
    line-height: 72px;
    white-space: nowrap;
    //font-weight: bold;
  }

  .desc {
    position: relative;
    padding-top: 16px;
    z-index: 2;
    font-family: "OPPOSANS-BOLD";
    font-size: 72px;
    line-height: 72px;
    width: 504px;
    color: #e95513;
    white-space: nowrap;
    //font-weight: bold;
  }

  .img-1 {
    position: absolute;
    bottom: 15px;
    left: 20px;
    width: 250px;
    height: 205px;
    z-index: 2;
  }

  .img-2 {
    position: absolute;
    bottom: 15px;
    left: 290px;
    width: 230px;
    height: 210px;
    z-index: 2;
  }

  .bg-img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
