var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 h-100 of-h", style: _vm.style }, [
    _c("div", {
      staticClass: "text-nowrap transparent",
      class: `${_vm.data.type}_${_vm.data.onlyKey} ${_vm.directionStyle}`,
      style: _vm.FontStyle,
      attrs: { id: `${_vm.data.type}_${_vm.data.onlyKey}` },
      domProps: { innerHTML: _vm._s(_vm.data.value) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }