var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { ..._vm.style, ..._vm.backgroundColor, ..._vm.border } },
    [
      _c(
        "table",
        {
          staticClass: "table box-border",
          class: _vm.data.pageSwitch ? "h-90" : "h-100",
        },
        [
          _c(
            "colgroup",
            _vm._l(_vm.data.cols, function (col, c) {
              return _c("col", {
                key: c,
                style: {
                  width: _vm.tdWidth + "px",
                  height: _vm.tdHeight + "px",
                },
              })
            }),
            0
          ),
          _c(
            "tbody",
            { staticClass: "tbody" },
            _vm._l(_vm.data.rows, function (row, r) {
              return _c(
                "tr",
                { key: r, staticClass: "tr" },
                _vm._l(_vm.data.cols, function (col, c) {
                  return _c(
                    "td",
                    { key: c, staticClass: "po-r" },
                    [
                      _c("call-board-content", {
                        staticClass: "content isAnime",
                        attrs: {
                          data: _vm.data,
                          width: _vm.tdWidth,
                          height: _vm.tdHeight,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            }),
            0
          ),
        ]
      ),
      _vm.data.pageSwitch
        ? _c("div", { staticClass: "flex justify-center align-center h-10" }, [
            _vm.data.pageType === "number"
              ? _c("div", { style: { ..._vm.font } }, [_vm._v(" 1/1 ")])
              : _c("div", [
                  _c("div", {
                    staticClass: "dot",
                    style: { width: _vm.dot, height: _vm.dot },
                  }),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }