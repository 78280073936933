<!--
 * @Author: 伽蓝
 * @Date: 2020-12-15 09:42:48
 * @LastEditTime: 2020-12-30 17:40:47
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web-view/src/components/mod/Smoke.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div>
    <img v-show="playAnimationSwitch" ref="smoke" :src="data.url" :style="style" />
  </div>
</template>
<script>
import { toPlaySpecial } from '@/libs/DocumentAnimates'
export default {
  name: 'smoke',
  data () {
    return {
      animeHandle: undefined
    }
  },
  props: {
    data: { type: Object },
    playAnimationSwitch: { type: Boolean, default: false }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.play()
      if (!val) this.stop()
    }
  },
  computed: {
    somke () {
      return this.$refs.smoke
    },
    style () {
      const { width, height, opacity } = this.data
      return {
        width: `${width}px`,
        height: `${height}px`,
        opacity
      }
    }
  },
  methods: {
    play () {
      const { delay, duration } = this.data
      const options = {
        duration: duration * 1000,
        iterations: Infinity,
        delay: delay * 1000,
        fill: 'backwards'
      }
      this.animeHandle = toPlaySpecial({ node: this.somke, anime: 'smoke', options })
    },
    stop () {
      this.animeHandle && this.animeHandle.cancel()
      this.animeHandle = undefined
    }
  }
}
</script>
