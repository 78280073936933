var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex no-wrap", style: { ..._vm.style } }, [
    _vm.statisticsType === "1"
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.unCookGoodsQuantity) +
              _vm._s(_vm.unit) +
              " / " +
              _vm._s(_vm.unCookOrderQuantity) +
              "单"
          ),
        ])
      : _vm._e(),
    _vm.statisticsType === "2"
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.unCookGoodsQuantity) +
              _vm._s(_vm.unit) +
              " / " +
              _vm._s(_vm.goodsQuantity) +
              _vm._s(_vm.unit)
          ),
        ])
      : _vm._e(),
    _vm.statisticsType === "3"
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.unCookOrderQuantity) +
              "单 / " +
              _vm._s(_vm.orderQuantity) +
              "单"
          ),
        ])
      : _vm._e(),
    _vm.statisticsType === "4"
      ? _c("span", [_vm._v(_vm._s(_vm.waitingTime) + "分钟")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }