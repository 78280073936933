/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="po-r" :id="`Table-${data.onlyKey}`">
    <div class="table-border"></div>
    <div class="table-container">
      <div class="po-r">
        <table class="box-border table" cellspacing="0px" cellpadding="0px" :style="TableStyle">
          <colgroup>
            <col
              v-for="(col,c) in data.tableColumns"
              :key="c"
              :style="{width:data.tableCellWidth+'px'}"
            />
          </colgroup>
          <thead class="thead"></thead>
          <tbody class="tbody" id="tbody">
            <tr
              class="tr"
              v-for="(row,r) in data.tableRows"
              :key="r"
              :style="{height:data.tableCellHeight+'px'}"
            >
              <td
                class="td"
                :style="TdSTyle"
                v-for="(col,c) in data.tableColumns"
                :key="c"
                :colspan="_getMergeCellData(`${r}-${c}`,'col')"
                :rowspan="_getMergeCellData(`${r}-${c}`,'row')"
              >
                <table-td-content
                  :cell-style="data.tableCellStyles[r][c]"
                  :coordinate="[r,c]"
                  :table-data="data"
                  :table-data-index="datasIndex"
                ></table-td-content>
              </td>
            </tr>
          </tbody>
          <tfoot class="tfoot"></tfoot>
        </table>
        <div class="po-a t-0 l-0 b-0 r-0 box-border z-index-l" :style="tableBorder"></div>
      </div>
    </div>
  </div>
</template>
<script>
import TableTdContent from 'coms/mod/TableTdContent'
import TableAnime from 'coms/mod/TableAnimeMixin'
export default {
  mixins: [TableAnime],
  components: { TableTdContent },
  name: 'tables',
  props: { data: { type: Object } },
  computed: {
    TableStyle () {
      const { width, height } = this.data
      return { width: width + 'px', height: height + 'px' }
    },
    tableBorder () {
      const { borderStyle, borderWidth, borderColor } = this.data
      return { border: `${borderWidth}px ${borderColor} ${borderStyle}` }
    },
    TdSTyle () {
      const { borderColor, borderWidth } = this.data
      const { borderStyle, backgroundColor } = this.data
      return {
        backgroundColor,
        borderTop: `${borderWidth}px ${borderColor} ${borderStyle}`,
        borderLeft: `${borderWidth}px ${borderColor} ${borderStyle}`
      }
    }
  },
  methods: {
    // 是否有动画
    _isAnimeTD (x, y) {
      const { tableHeadRowCount } = this.data
      if (tableHeadRowCount === 0) return true
      const _x = tableHeadRowCount - 1
      if (x > _x) return true
    },
    // 存在 返回真
    _isHideTD (x, y) {
      const { tableHiddenCells } = this.data
      return tableHiddenCells.indexOf(`${x}-${y}`) === -1
    },
    // 获取合并单元格数据
    _getMergeCellData (key, type) {
      const { tableMergeCells } = this.data
      const value = tableMergeCells[key]
        ? tableMergeCells[key].coordinate
        : undefined
      let res = 1
      if (!value) return res
      type === 'row' && (res = value[0])
      type === 'col' && (res = value[1])
      return res
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.table {
  border-collapse: collapse;
  border-spacing: 0;
}

.td {
  position: relative;
  box-sizing: border-box;
  line-height: 1em;
  user-select: none;
  overflow: hidden;
}
</style>
