var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-collection", style: _vm.style },
    _vm._l(_vm.menuList, function (list, ri) {
      return _c(
        "div",
        {
          key: ri + Math.random().toString(8),
          staticClass: "box-row",
          style: _vm.spacingR,
        },
        _vm._l(list, function (info, ci) {
          return _c(
            "div",
            {
              key: ci + Math.random().toString(8),
              staticClass: "box-cell",
              style: _vm.spacingC,
            },
            [
              info
                ? _c("TextItem", {
                    attrs: {
                      data: _vm.data,
                      info: info,
                      colIndex: ci,
                      maxPriceCount: _vm.maxPriceCountByCol[ci],
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }