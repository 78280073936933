var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "icon-wrap",
      class: [_vm.position, _vm.vertical ? "vertical" : ""],
    },
    _vm._l(_vm.list, function (item, i) {
      return _c(
        "div",
        { key: i, staticClass: "icon-item", style: _vm.getStyle(item) },
        [_c("img", { staticClass: "img-cover", attrs: { src: item.url } })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }