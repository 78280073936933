var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 h-100 of-h mouse-events" }, [
    _c(
      "div",
      {
        staticClass: "map-wrap align-center justify-start",
        class: `${_vm.data.type}_${_vm.data.onlyKey} ${_vm.directionStyle}`,
        style: _vm.style,
        attrs: { id: `${_vm.data.type}_${_vm.data.onlyKey}` },
      },
      _vm._l(_vm.slideMapList, function (item, index) {
        return _c("div", { key: index, style: _vm.imgStyle }, [
          _c("img", { staticClass: "w-100 h-100", attrs: { src: item.url } }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }