
<template>
  <div class="w-100 h-100 of-h mouse-events">
    <div
      class="map-wrap align-center justify-start"
      :id="`${data.type}_${data.onlyKey}`"
      :class="`${data.type}_${data.onlyKey} ${directionStyle}`"
      :style="style"
    >
      <div :style="imgStyle" v-for=" (item,index) in slideMapList" :key="index">
        <img class="w-100 h-100" :src="item.url" />
      </div>
    </div>
  </div>
</template>
<script>
import { toMoveMap } from 'libs/DocumentAnimates.js'
export default {
  name: 'slide-map',
  data () {
    return { handelAnime: null, opacity: 0 }
  },
  props: {
    data: { type: Object }, playAnimationSwitch: { type: Boolean }, isAndroidSrc: { type: String, default: '' }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.play()
      if (!val) this.stop()
    }
  },
  computed: {
    style () {
      return { opacity: this.opacity }
    },
    slideMapList () {
      const { imageList } = this.data
      const { isAndroidSrc } = this
      return imageList.map(item => {
        isAndroidSrc !== '' && (item.url = isAndroidSrc + item.url.split('/').reverse()[0])
        return item
      })
    },
    directionStyle () {
      const { direction } = this.data
      if (direction === 'row') return 'justify-start inline-flex h-100'
      return 'flex-direction flex'
    },
    imgStyle () {
      const { imageWidth, direction } = this.data
      const _width = imageWidth + 'px'
      const spacing = '20px'
      const _style = {
        width: _width,
        height: _width,
        minWidth: _width,
        minHeight: _width,
        marginRight: direction === 'row' ? spacing : 0,
        marginBottom: direction === 'col' ? spacing : 0
      }
      return _style
    }
  },
  methods: {
    play () {
      const options = this.animeOptions()
      this.opacity = 1
      this.handelAnime = toMoveMap(options)
    },
    stop () {
      this.handelAnime && this.handelAnime.pause()
      Object.assign(this.$data, this.$options.data())
    },
    animeOptions () {
      const { duration, type, onlyKey, direction, animeDirection } = this.data
      const { width, height } = this.data
      const startXY = direction === 'col' ? height : width
      return {
        idName: `${type}_${onlyKey}`,
        animeName: animeDirection,
        options: { duration: duration * 1000, iterations: 'Infinity' },
        startXY
      }
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.map-wrap {
  &:last-child {
    margin: 0;
  }
}
</style>
