<template>
    <div :style="{ ...style }">
    <div v-show="dynamicHeatShow" :style="scale" class="inline-flex align-center justify-center">
        <img class="margin-right-sm" style="width:50px;height:50px" :src="url" alt="">
      <div
        class="h-100 flex justify-start align-center"
        style="width: 100px"
        :style="textStyle"
      >
        +
        <span :id="data.onlyKey + 'text'"> 10 </span>
      </div>
    </div>
  </div>
</template>

<script>
import CountUp from '@/libs/countUp'
import { toDynamicHeat } from '@/libs/DocumentAnimates.js'

export default {
  name: 'dynamic-heat',
  data () {
    return {
      dynamicHeatShow: false,
      baseUrl: 'https://media.hesiling.com/resources/pch5/20201019/'
    }
  },
  props: {
    data: { type: Object },
    playAnimationSwitch: { type: Boolean }
  },
  watch: {
    playAnimationSwitch (val) {
      if (val) {
        this.flag = new Date().getTime()
        this.play(this.flag)
      }
      if (!val) this.stop()
    }
  },
  mounted () {
    var options = {
      useEasing: true,
      useGrouping: true,
      separator: ',',
      decimal: '.',
      duration: 1,
      startVal: 1
    }
    this.countUp = new CountUp(this.data.onlyKey + 'text', 1000, options)
  },
  computed: {
    url () {
      const { baseUrl } = this
      const { iconName } = this.data
      return baseUrl + iconName
    },
    scale () {
      const { width, __data } = this.data
      const x = width / __data.width
      return {
        width: __data.width + 'px',
        height: __data.height + 'px',
        transform: `scale(${x})`,
        transformOrigin: 'left top'
      }
    },
    style () {
      const { fontFamily, width, height } = this.data
      return {
        fontFamily,
        width: width + 'px',
        height: height + 'px',
        overflow: 'hidden'
      }
    },
    textStyle () {
      const { style } = this.data
      const { fontSize, letterSpacing } = style
      return {
        ...style,
        fontSize: fontSize + 'px',
        letterSpacing: letterSpacing + 'px'
      }
    },
    iconStyle () {
      const { color } = this.data
      return {
        color
      }
    },
    randomData () {
      const { randomRange } = this.data
      return JSON.parse(randomRange)
    }
  },
  methods: {
    async play (flag) {
      if (!this.playAnimationSwitch) return
      if (flag !== this.flag) return
      const { randomData } = this
      const { range, time } = randomData
      await this.$setTimeout(this.$random(time[0], time[1]))
      const num = this.$random(range[0], range[1])
      if (num === 0) return this.play(flag)
      this.dynamicHeatShow = true
      await this.$anime('nodeEnterAnime')
      await this.countUp.update(num)
      await this.$setTimeout(2)
      await this.$anime('nodeLeaveAnime')
      await this.countUp.reset()
      this.dynamicHeatShow = false
      this.play(flag)
    },
    stop () {
      this.dynamicHeatShow = false
      this.flag = ''
    },
    $anime (type) {
      return new Promise((resolve, reject) => {
        const { onlyKey } = this.data
        const duration = this.data[type].duration * 1000
        const animeName = this.data[type].name
        const options = { duration }
        const animation = toDynamicHeat({
          idName: onlyKey,
          animeName,
          options
        })
        animation.onfinish = () => {
          resolve()
        }
      })
    },
    $animeLeave () {
      const { onlyKey, nodeLeaveAnime } = this.data
      const options = { duration: nodeLeaveAnime.duration * 1000 }
      const animation = toDynamicHeat({
        idName: onlyKey,
        animeName: nodeLeaveAnime.name,
        options
      })
      animation.onfinish = () => {
        this.dynamicHeatShow = false
      }
    },
    $setTimeout (time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()
        }, time * 1000)
      })
    },
    $random (min, max) {
      const num = Math.random() * (max - min) + min
      return Math.floor(num)
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
