var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "po-r mouse-events" }, [
    _c(
      "div",
      {
        staticClass: "po-a",
        style: { zIndex: _vm.firstZ },
        attrs: { id: `${_vm.onlyKey}_first` },
      },
      [_c("img", { style: _vm.imageStyle, attrs: { src: _vm.firstUrl } })]
    ),
    _c(
      "div",
      {
        staticClass: "po-a",
        style: { zIndex: _vm.lastZ },
        attrs: { id: `${_vm.onlyKey}_last` },
      },
      [_c("img", { style: _vm.imageStyle, attrs: { src: _vm.lastUrl } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }