var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "picture-collection", style: _vm.style },
    _vm._l(Number(_vm.data.pictureRow), function (row, ri) {
      return _c(
        "div",
        {
          key: ri + Math.random().toString(8),
          staticClass: "box-row",
          style: _vm.spacingR,
        },
        _vm._l(_vm.data.pictureCol, function (col, ci) {
          return _c(
            "div",
            {
              key: ci + Math.random().toString(8),
              staticClass: "box-cell",
              style: _vm.spacingC,
            },
            [
              _vm.getInfo(ri, ci)
                ? _c("PictureItem", {
                    attrs: {
                      data: _vm.data,
                      info: _vm.getInfo(ri, ci),
                      rowIndex: ri,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }