var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ov-h", style: _vm.style }, [
    _c("img", { staticClass: "w-100", attrs: { src: _vm.data.url, alt: "" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }