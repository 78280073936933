<!--
 * @Author: 伽蓝
 * @Date: 2020-07-03 16:01:51
 * @LastEditTime: 2020-12-29 23:23:27
 * @LastEditors: 伽蓝
 * @FilePath: /h5-web-view/src/components/mod/Date.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div :style="style" class="w-100 h-100 of-h">
    <div class="flex transparent text-nowrap align-center h-100" :style="FontStyle">{{date}}</div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
const TEXT_ALIGN = { left: 'flex-start', center: 'center', right: 'flex-end' }
export default {
  name: 'date',
  data () {
    return { date: '', timer: 1000, setInterval: '' }
  },
  props: {
    data: { type: Object },
    pageIndex: { type: Number, default: -3 },
    currentPage: { type: Number, default: -1 }
  },
  beforeDestroy () {
    clearInterval(this.setInterval)
  },
  mounted () {
    this.setInterval = setInterval(() => {
      this._getNowDate()
    }, this.timer)
  },
  computed: {
    style () {
      return { backgroundColor: this.data.style.backgroundColor }
    },
    FontStyle () {
      const { style, fontFamily } = this.data
      const { textAlign, fontSize, letterSpacing } = style
      const _style = { fontSize: fontSize + 'px', letterSpacing: letterSpacing + 'px', justifyContent: TEXT_ALIGN[textAlign], fontFamily }
      return Object.assign(style, _style)
    }
  },
  methods: {
    _getNowDate () {
      const { dateFormat } = this.data
      const date = dayjs().format(dateFormat || 'YYYY年MM月DD日 周d HH:mm:ss')
      this.date = this._replaceDate(date)
      console.log('this.date :>> ', this.date)
    },
    _replaceDate (data) {
      const oldDate = ['周0', '周1', '周2', '周3', '周4', '周5', '周6']
      const newDate = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      oldDate.forEach((oldDate, index) => {
        if (data.indexOf(oldDate) > -1) {
          data = data.replace(oldDate, newDate[index])
        }
      })
      return data
    }
  }
}
</script>
