<!--
 * @Author: 伽蓝
 * @Date: 2020-07-30 11:33:14
 * @LastEditTime: 2022-04-13 17:03:41
 * @LastEditors: Please set LastEditors
 * @FilePath: /h5-web-view/src/components/mod/Video.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div class="w-100 h-100 po-r">
    <video ref="video" class="zindex-xl po-a" :width="data.width" :height="data.height" preload="auto"
      :playsinline="true" :x5-video-player-type="'h5'" :x5-video-player-fullscreen="false" :muted="videoMuted"
      @ended="onPlayerEnded($event)"></video>
    <div class="po-a top-0 left-0 w-100 h-100">
      <img class="w-100" :src="url" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      count: 0,
      video: null,
      url: ''
    }
  },
  computed: {
    linkageNumber () {
      const linkageNumber = this.data.linkage_rank
      return linkageNumber - 1 < 0 ? 0 : linkageNumber - 1
    },
    playList () {
      const { videoList, linkageNumber } = this
      return videoList[linkageNumber] || []
    },
    videoHandle () {
      console.log(this.$refs.video)
      return this.$refs.video
    }
  },
  props: {
    data: { type: Object },
    videoList: { type: Array, required: true },
    videoLoop: { type: String, default: 'once' },
    videoMuted: { type: Boolean, default: true },
    playAnimationSwitch: { type: Boolean }

  },
  watch: {
    playAnimationSwitch (val) {
      if (val) this.play()
      if (!val) this.stop()
    }
  },
  methods: {
    play () {
      const { type } = this.$globle
      type === 'mp' && (window.videoHandle = this.videoHandle)
      this.setVideoSrc()
    },
    stop () {
      if (Object.is(this.videoHandle, window.videoHandle)) window.videoHandle = null
      Object.assign(this.$data, this.$options.data())
      this.videoHandle.pause()
    },
    // 循环列表播放 设置URL
    setVideoSrc (index = 0) {
      const { playList } = this
      // eslint-disable-next-line camelcase
      const { url, type, first_image } = playList[index]
      // eslint-disable-next-line camelcase
      this.url = first_image
      Object.assign(this.videoHandle, { src: url, type, poster: first_image })
      this.videoHandle.load()
      this.videoHandle.play()
    },
    onPlayerEnded () {
      const { playList, count, videoLoop } = this
      let nextCount = count + 1
      if (nextCount >= playList.length && videoLoop === 'once') return
      nextCount >= playList.length && (nextCount = 0)
      Object.assign(this, { count: nextCount })
      this.setVideoSrc(nextCount)
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.zindex-xl {
  z-index: 10
  object-fit: fill
}
</style>
