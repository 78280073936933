<!--
 * @Author: 伽蓝
 * @Date: 2020-09-18 09:47:29
 * @LastEditTime: 2021-11-25 15:54:28
 * @LastEditors: Please set LastEditors
 * @FilePath: /h5-web-view/src/components/mod/CallBoard.vue
 * @代码不规范,调试泪两行
-->
<template>
  <div :style="{...style,...backgroundColor,...border}">
    <table class="table box-border" :class="data.pageSwitch ?'h-90':'h-100'">
      <colgroup>
        <col v-for="(col,c) in data.cols" :key="c" :style="{width:tdWidth+'px',height:tdHeight+'px'}" />
      </colgroup>
      <tbody class="tbody">
        <tr class="tr" v-for="(row,r) in data.rows" :key="r">
          <td class="po-r" v-for="(col,c) in data.cols" :key="c">
            <call-board-content class="content isAnime" :data="data" :width="tdWidth" :height="tdHeight">
            </call-board-content>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-center align-center h-10" v-if="data.pageSwitch">
      <div v-if="data.pageType==='number'" :style="{...font}">
        1/1
      </div>
      <div v-else>
        <div class="dot" :style="{width:dot,height:dot}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'call-board',
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    border () {
      const { borderStyle, borderWidth, borderColor, borderRadius } = this.data
      return {
        border: `${borderWidth}px ${borderColor} ${borderStyle}`,
        borderRadius: borderRadius + '%'
      }
    },
    style () {
      const { width, height } = this.data
      return { width: width + 'px', height: height + 'px' }
    },
    font () {
      const { fontSize, color, fontFamily } = this.data.style
      return {
        fontSize: Number((fontSize * 0.66).toFixed(2)) + 'px',
        color,
        fontFamily
      }
    },
    backgroundColor () {
      const { backgroundColor } = this.data
      return { backgroundColor }
    },
    tdWidth () {
      const { width, cols } = this.data
      return width / cols
    },
    dot () {
      const { tdWidth } = this
      const w = tdWidth * 0.6 / 10 * 0.8
      return w + 'px'
    },
    tdHeight () {
      const { height, rows } = this.data
      return height / rows
    }
  },
  created () {
    let { version } = this.data
    if (version === '2.1.0' || version === '2.5.0') version = '2.1.0'
    if (version === '1.0.0' || !version) version = '1.0.0'
    Vue.component('call-board-content', () => import('./call-board/' + version))
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.dot {
  background: #999999
  border-radius: 5000px

  &.active {
    background: #CCCCCC
  }
}

.h-90 {
  height: 90%
}

.h-10 {
  height: 10%
}
</style>
