/*
 * @Author: 伽蓝
 * @Date: 2022-11-11 11:56:11
 * @LastEditTime: 2022-11-11 12:08:52
 * @LastEditors: 伽蓝
 * @FilePath: /pc-h5/src/api/marketing.js
 * @Description:
 * 代码不规范,调试泪两行
 */
/**
 * 智能菜单 组件 API
 */
import axios from 'tools/Http' // 导入http中创建的axios实例
//  marketing 智能菜单列表
// {{host}}/api/manage/v8/smartMenu/dynamicData
export function apiGetDynamicData(id) {
  return axios.get(`v8/tool/getMenuDataByContentId?content_id=${id}`, {})
}
